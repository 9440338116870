<div class="container">

	<div *ngIf="export || addOne" class="row justify-content-end gx-0 mr-1">
		<div *ngIf="addOne" class="col-sm-auto  mb-2 px-0 ml-2 mr-0">
			<button class="btn btn-lg btn-primary pointer float-right"
					type="button"
					id="cmdAdd"
					(click)="handleAddOneClick()">
				<i class="mr-2 fas fa-plus"></i>
				Agregar
			</button>
		</div>
		<div *ngIf="export" class="col-sm-auto mb-2 px-0 ml-2 mr-0">
			<button class="btn btn-lg btn-primary pointer float-right"
					type="button"
					id="cmdExport"
					(click)="handleExportClick()"
					[disabled]="totalItems == 0">
				<i class="mr-2 fas fa-download"></i>
				Exportar
			</button>
		</div>
	</div>


	<div class="card card-default">
		<div class="table-responsive">
			<table class="table table-hover table-striped w-100"
				   #table>
				<thead>
					<tr>
						<th class="select"
							*ngIf="select">
							<input type="checkbox"
								   class="form-check-input"
								   [(ngModel)]="selectedAll"
								   (click)="selectAll($event)">
						</th>

						<ng-container *ngFor="let field of fields">

							<th *ngIf="!field.isHidden && field.name !== 'actions'"
								(click)="sort(field.name)"
								scope="col"
								[ngClass]="{ 'icon-col': field.subDetail != null, 'isHidden': field.isHidden, 'd-none d-sm-table-cell hidden-small': field.hiddenForSmall } "
								[ngStyle]="{ 'width': field.width + 'px' }">
								<em *ngIf="field.name !== 'actions' && (field.sortable !== undefined ? field.sortable : true)"
									[ngClass]="{ 'sorted-asc': sortedField == field.name && sortedAsc,
                            	'sorted-desc': sortedField == field.name && sortedDesc }"
									class="mr-2 fas fa-sort"></em>
								<span title="{{field.title}}">{{field.title}}</span>
							</th>
							<th *ngIf="field.name === 'actions'" style="width: 80px;">
								
							</th>

						</ng-container>
					</tr>
				</thead>
				<tbody>
                    <ng-container *ngIf="isGroupping == true">
                        <ng-container  *ngFor="let rowGroup of tableData; let k = index">
                            <tr class="font-weight-bold header-group"><td  [attr.colspan]="columnViewAmount()" class="info" [attr.data-label]='rowGroup["showDescription"]'>{{rowGroup["showDescription"]}}  {{rowGroup["groupName"]}} </td></tr>
    
                            <tr *ngFor="let row of rowGroup.list; let j = index" class="field">
                                <td  *ngIf="select">
                                    <input type="checkbox" [(ngModel)]="rows[rowGroup.list.indexOf(row)].checked" (click)="toggleSelect(row)">
                                </td>
    
                                <ng-container *ngFor="let field of fields; let i = index">
    
                                    <td  *ngIf="!field.isHidden && field.name !== 'actions' && !field.isBoolean"
										[ngClass]="{ 'icon-col': hasSubDetail(row, i), 'isHidden': fields[i].isHidden, 'd-none d-sm-table-cell hidden-small': fields[i].hiddenForSmall } "
										[ngStyle]="{ 'text-align': field.textAlign }"
										[attr.data-label]='field.title' style="text-align: right;">
    
                                        <span *ngIf="!hasSubDetail(row, i)" title="{{formatProp(j, field.name, field,row[field.name]) ? formatProp(j, field.name, field,row[field.name]) : row[field.name]}}" class="{{type(j, field.name, field,row[field.name])}}">
                                            {{formatProp(j, field.name, field,row[field.name]) ? formatProp(j, field.name, field,row[field.name]) : row[field.name]}}</span>
                                        <a *ngIf="hasSubDetail(row, i)" class="mx-1" (click)="handleSubDetailClick(row, i)">
                                            <em class="fa-lg fas fa-search"></em>
                                        </a>
    
                                    </td>
    
                                    <td *ngIf="field.isBoolean"
									[ngClass]="{ 'icon-col': hasSubDetail(row, i), 'isHidden': fields[i].isHidden, 'd-none d-sm-table-cell hidden-small': fields[i].hiddenForSmall } ">
                                        <em class="fa" [ngClass]="{ 'fa-check': formatProp(j, field.name, field,row[field.name]), 'fa-times': !formatProp(j, field.name, field,row[field.name]) }"></em>
                                    </td>
    
                                </ng-container>
    
                                <td *ngIf="actions"
							class="text-right">
							<a [appIconPermission]="'keys'"
							   *ngIf="generateKey"
							   class="mx-1"
							   (click)="handleKeyClick(row)"
							   title="Generar clave"
							   id="keyAction">
								<em class="fa-lg fas fa-key"></em>
							</a>
							<a [appIconPermission]="'edit'"
							   *ngIf="edit"
							   class="mx-1"
							   (click)="handleEditClick(row)"
							   title="Editar"
							   id="editAction">
								<em class="fa-lg fas fa-edit"></em>
							</a>
							<a [appIconPermission]="'unlink'"
							   *ngIf="row.isAvailable"
							   class="mx-1"
							   [ngClass]="{'hide-icon': (row.isAvailable == 'Si')}"
							   (click)="handleUnlinkClick(row)"
							   title="Desasociar"
							   id="unlinkAction">
								<em class="fa-lg fas fa-unlink"></em>
							</a>
							<a [appIconPermission]="'detail'"
							   *ngIf="detail"
							   class="mx-1"
							   (click)="handleDetailClick(row)"
							   title="Detalle"
							   id="{{'btndetailAction' + j}}">
								<em class="fa-lg fas fa-search"></em>
							</a>
							<a [appIconPermission]="'delete'"
							   *ngIf="delete"
							   class="mx-1"
							   (click)="handleDeleteClick(row)"
							   title="Eliminar"
							   id="deleteAction">
								<em class="fa-lg fas fa-trash"></em>
							</a>
							<a [appIconPermission]="'add'"
							   *ngIf="add"
							   class="mx-1"
							   (click)="handleAddClick(row)"
							   title="Agregar"
							   id="addAction">
								<em class="fa-lg icon-plus"></em>
							</a>
						</td>
                            </tr>
                        </ng-container >
    
    
                    </ng-container>
                    <ng-container *ngIf="isGroupping == false">
                        <tr *ngFor="let row of tableData; let j = index" >
                            <td class="select" *ngIf="select">
                                <input type="checkbox" [(ngModel)]="rows[tableData.indexOf(row)].checked" (click)="toggleSelect(row)">
                            </td>
        
                            <ng-container *ngFor="let field of fields; let i = index">
        
                                <td *ngIf="!field.isHidden && field.name !== 'actions' && !field.isBoolean"
									[ngClass]="{ 'icon-col': hasSubDetail(row, i), 'isHidden': fields[i].isHidden, 'd-none d-sm-table-cell hidden-small': fields[i].hiddenForSmall } "
									[ngStyle]="{ 'text-align': field.textAlign }"
									[attr.data-label]='field.title'>
        
                                    <span *ngIf="!hasSubDetail(row, i)" title="{{formatProp(j, field.name, field,row[field.name]) ? formatProp(j, field.name, field,row[field.name]) : row[field.name]}}" class="{{type(j, field.name, field,row[field.name])}}">
                                        {{formatProp(j, field.name, field,row[field.name]) ? formatProp(j, field.name, field,row[field.name]) : row[field.name]}}</span>
                                    <a *ngIf="hasSubDetail(row, i)" class="mx-1" (click)="handleSubDetailClick(row, i)">
                                        <em class="fa-lg fas fa-search"></em>
                                    </a>
        
                                </td>
        
                                <td *ngIf="field.isBoolean" [attr.data-label]='field.title'
								[ngClass]="{ 'icon-col': hasSubDetail(row, i), 'isHidden': fields[i].isHidden, 'd-none d-sm-table-cell hidden-small': fields[i].hiddenForSmall } ">
                                    <em class="fa" [ngClass]="{ 'fa-check': formatProp(j, field.name, field,row[field.name]), 'fa-times': !formatProp(j, field.name, field,row[field.name]) }"></em>
                                </td>
        
                            </ng-container>
        
                            <td *ngIf="actions" style="width: 80px;"
							class="text-right">
							<a [appIconPermission]="'keys'"
							   *ngIf="generateKey"
							   class="mx-1"
							   (click)="handleKeyClick(row)"
							   title="Generar clave"
							   id="keyAction">
								<em class="fa-lg fas fa-key"></em>
							</a>
							<a [appIconPermission]="'edit'"
							   *ngIf="edit"
							   class="mx-1"
							   (click)="handleEditClick(row)"
							   title="Editar"
							   id="editAction">
								<em class="fa-lg fas fa-edit"></em>
							</a>
							<a [appIconPermission]="'unlink'"
							   *ngIf="row.isAvailable"
							   class="mx-1"
							   [ngClass]="{'hide-icon': (row.isAvailable == 'Si')}"
							   (click)="handleUnlinkClick(row)"
							   title="Desasociar"
							   id="unlinkAction">
								<em class="fa-lg fas fa-unlink"></em>
							</a>
							<a [appIconPermission]="'detail'"
							   *ngIf="detail"
							   class="mx-1"
							   (click)="handleDetailClick(row)"
							   title="Detalle"
							   id="{{'btndetailAction' + j}}">
								<em class="fa-lg fas fa-search"></em>
							</a>
							<a [appIconPermission]="'delete'"
							   *ngIf="delete"
							   class="mx-1"
							   (click)="handleDeleteClick(row)"
							   title="Eliminar"
							   id="deleteAction">
								<em class="fa-lg fas fa-trash"></em>
							</a>
							<a [appIconPermission]="'add'"
							   *ngIf="add"
							   class="mx-1"
							   (click)="handleAddClick(row)"
							   title="Agregar"
							   id="addAction">
								<em class="fa-lg icon-plus"></em>
							</a>
						</td>
                        </tr>
                        </ng-container>
				</tbody>
			</table>
		</div>
		<div *ngIf="totalItems == 0">
			<div class="alert alert-light bg-gray-light m-2 text-center">No hay resultados</div>
		</div>
	</div>
	
	<footer class="page-footer">
        <div class="footer-content">
           <div class="page-info ml-3" *ngIf="totalItems > 0">
            <div class="row">
                <p> Total: {{totalItems}} registros </p>
            </div>
            <div class="row" *ngIf="totalPages > 1">
                <p> Página {{page}} de {{totalPages}}</p>
            </div>
          </div>
           <div class="pagination-container d-none d-md-block">
				<pagination *ngIf="totalItems > 10" class="col" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage"
					[maxSize]="maxSize" [boundaryLinks]="true" [(ngModel)]="page" (pageChanged)="pageChanged($event)"
					previousText="&lt;i class='fas fa-angle-left'&gt;&lt;/i&gt;" nextText="&lt;i class='fas fa-angle-right'&gt;&lt;/i&gt;" firstText="&lt;i class='fas fa-angle-double-left'&gt;&lt;/i&gt;" lastText="&lt;i class='fas fa-angle-double-right'&gt;&lt;/i&gt;">
				</pagination> 
			</div>
			<!-- para mostrar en pequeñas pantallas -->
			<div class="pagination-container fa-xs d-block d-md-none">
				<pagination *ngIf="totalItems > 10" class="col" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage"
					[maxSize]="maxSize" [boundaryLinks]="true" [(ngModel)]="page" (pageChanged)="pageChanged($event)"
					previousText="&lt;i class='fas fa-angle-left fa-xs'&gt;&lt;/i&gt;" nextText="&lt;i class='fas fa-angle-right fa-xs'&gt;&lt;/i&gt;" firstText="&lt;i class='fas fa-angle-double-left fa-xs'&gt;&lt;/i&gt;" lastText="&lt;i class='fas fa-angle-double-right fa-xs'&gt;&lt;/i&gt;">
				</pagination>
         	</div>
        </div>
      </footer>
	  
</div>
