<div class="wrapper" [class.logout]="!loggedIn">
    <!-- top navbar-->
    <app-header class="topnavbar-wrapper"></app-header>
    <!-- sidebar-->
    <app-sidebar *ngIf="loggedIn" class="aside-container"></app-sidebar>
    <!-- offsidebar-->
    <app-offsidebar class="offsidebar"></app-offsidebar>
    <!-- toaster -->
    <toaster-container [toasterconfig]="toasterconfig" class="custom-toast"></toaster-container>
    <!-- Main section-->
    <section class="section-container">
        
        <!-- Page content-->
        <div class="content-wrapper">
            <div class="container text-center"><h2 class="d-block d-md-none">{{title | titleCase}}</h2></div>
            <router-outlet>
                <app-loader [showLoader]="showLoader"></app-loader>
            </router-outlet>
        </div>
        <!-- <div id="ChatContainer"></div> -->
    </section>
    <!-- Page footer-->
    <footer *ngIf="showFooter" class="footer-container" app-footer></footer>
</div>