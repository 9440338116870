
import { Affiliate } from '../biopago/afiliados/affiliate';

export class User {
    username: string;
    email: string;
    role?: Role;
    isChainSt: boolean;
    token?: string;
    affiliates: Affiliate[]= null;
    emailConfirmed: boolean;
    emailChangePending: boolean;
}

export class Role {
    id: number;
    name?: string;
    description?: string;
    functions?: string[] = [];
}

export class Token {
    unique_name: string;
    role: string;
    roleDescription: string;
    Function: string | string[];
    EmailConfirmed: string;
    EmailChangePending: string;
    nbf: number;
    exp: number;
    iat: number;
    isChainSt: boolean;
}
