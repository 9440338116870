import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Directive({
    selector: '[appInputPermission]'
})
export class InputPermissionDirective implements OnInit {
    @Input('appInputPermission') action: string;

    constructor(private el: ElementRef, private renderer: Renderer2, private route: ActivatedRoute, private auth: AuthService) { }

    ngOnInit() {
        const currentRole = this.auth.getRole;
        this.route.data.subscribe(data => {
            const role = data[this.action];
            if (role && !currentRole.functions.some(e => e === role)) {
                this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
            }
        })
    }
}
