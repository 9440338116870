import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appTypingOnly]'
})
export class TypingOnlyDirective {

    constructor() { }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        // const pastedInput: string = event.clipboardData.getData('text/plain');
        // this.pasteData(pastedInput);
        event.preventDefault();
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
        // const textData = event.dataTransfer.getData('text');
        // this.inputElement.focus();
        // this.pasteData(textData);
        event.preventDefault();
    }

}
