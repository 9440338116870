import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfo, LegalInfo } from '../../models/user';

const API_URL = `${environment.apiUrl}/User`;

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) { }

    getCurrentUser(): Observable<UserInfo> {
        return this.http.get<UserInfo>(`${API_URL}/Current/Detail`);
    }
    
    getCurrentLegalInfo(): Observable<LegalInfo> {
        return this.http.get<LegalInfo>(`${API_URL}/Current/LegalInfo`);
    }

    updateCurrentLegalInfo(user: LegalInfo, ciFile: any, rifFile:any) {
        const formData: FormData = new FormData();
        formData.append('id', JSON.stringify(user.id));
        formData.append('firstName', user.firstName);
        formData.append('lastName', user.lastName);
        formData.append('phone', user.phone);
        formData.append('phone2', user.phone2);
        formData.append('cellphone', user.cellphone);
        formData.append('state', user.state);
        formData.append('town', user.town);
        formData.append('city', user.city);
        formData.append('street', user.street);
        formData.append('hasGeneralInfo', JSON.stringify(user.hasGeneralInfo));
        formData.append('fileCI', ciFile);
        formData.append('fileRIF', rifFile);
        formData.append('serial', user.serial);
        formData.append('isSameInfo', JSON.stringify(user.isSameInfo));

        return this.http.put(`${API_URL}/Current/LegalInfo`, formData, { observe: 'response', responseType: 'arraybuffer' });	
    }

    updateCurrentUser(user: UserInfo) {
        const formData: FormData = new FormData();
        formData.append('id', JSON.stringify(user.id));
        formData.append('firstName', user.firstName);
        formData.append('lastName', user.lastName);
        formData.append('phone', user.phone);
        formData.append('phone2', user.phone2);
        formData.append('cellphone', user.cellphone);
        formData.append('hasGeneralInfo', JSON.stringify(user.hasGeneralInfo));
        formData.append('isChain', JSON.stringify(user.isChain));

        return this.http.put(`${API_URL}/Current/Detail`, formData, { observe: 'response', responseType: 'arraybuffer' });	
    }
}
