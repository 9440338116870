export class FilterBase {
    prevFilter: Filter;
    setPrevFilter(prevFilter: Filter) {
        this.prevFilter = prevFilter;
    }
}

export class ComboItem implements CboFilterField {
    id: any;
    description: string;

    constructor(id: any, description: string) {
        this.id = id;
        this.description = description;
    }
}

export interface Filter {
    filter: FilterField[];
}

export interface FilterField {
    title: string;
    value: any;
    name: string;
    items?: string[];
    listItems?: CboFilterField[];
    type?: string;
    required?: boolean;
    placeholder?: string;
    max?:number;
}

export interface CboFilterField {
    id: any;
    description: string;
}
