import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionsWidgetComponent } from './transactions-widget/transactions-widget.component';
import { SharedModule } from '../shared/shared.module';
import { DateTimeClockComponent } from './date-time-clock/date-time-clock.component';



@NgModule({
  declarations: [
    TransactionsWidgetComponent,
    DateTimeClockComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    TransactionsWidgetComponent,
    DateTimeClockComponent
  ]
})
export class WidgetModule { }
