import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User, Role, Token } from 'src/app/models/auth/user';
import { environment } from 'src/environments/environment';
import { ToasterNotificationService } from 'src/app/core/toaster/toaster-notification.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RouterService } from 'src/app/core/router/router.service';
import { SwalFactory } from 'src/app/shared/helpers/swal-factory-helper';
import { NotificationService } from '../notifications/notification.service.service';
import { Affiliate } from 'src/app/models/biopago/afiliados/affiliate';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { each } from 'lodash';

const swal = require('sweetalert');
const API_URL = `${environment.apiUrl}/Authentication`;

@Injectable({
	providedIn: 'root'
})

export class AuthService {

	private static CURRENT_USER: string = "currentUser";
	
	private loggedIn = false;
	private _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.loggedIn);
	loggedIn$ = this._loggedIn.asObservable();

	private emailConfirmed = false;
	private _emailConfirmed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.emailConfirmed);
	emailConfirmed$ = this._emailConfirmed.asObservable();
	
	private emailChangePending = null;
	private _emailChangePending: BehaviorSubject<string> = new BehaviorSubject<string>(this.emailChangePending);
	emailChangePending$ = this._emailChangePending.asObservable();

	private _username: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	username$ = this._username.asObservable();

	private _rolename: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	rolename$ = this._rolename.asObservable();	
	
	private _roledescription: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	roledescription$ = this._roledescription.asObservable();

	private _affiliates: BehaviorSubject<Affiliate[]> = new BehaviorSubject<Affiliate[]>(null);
	affiliates$ = this._affiliates.asObservable();

	private role: Role = null;

	get isLoggedIn(): boolean {

		return this.loggedIn;
	}

	get getRole() {

		return this.role;
	}

	get getRoleName() {

		return this._rolename.getValue();
	}

	get isEmailConfirmed(): boolean {

		return this._emailConfirmed.getValue();
	}

	get hasEmailChangePending(): string {

		return this._emailChangePending.getValue();
	}

	get getUsername() {

		return this._username.getValue();
	}

	constructor(
		private router: Router,
		private http: HttpClient,
		private routerService: RouterService,
		private toaster: ToasterNotificationService,
		private notificationService: NotificationService) {

		const token = localStorage.getItem('wc-token');

		if (token && Date.now() / 1000 < this.getTokenExpiration()) {

			this.loggedIn = true;
			this.setRoleFromToken();
			this.setUsernameFromToken();
			this.setEmailConfirmedFromToken();
			this.setEmailChangePendingFromToken();
		}

		this._loggedIn.next(this.loggedIn);
	}

	resendActivationEmail() {

		const activationLink = this.linkActivation();
		const deactivationLink = this.linkDeactivation();
		const params = new HttpParams()
			.set('activationLink', this.linkActivation())
			.set('deactivationLink', this.linkDeactivation());

		return this.http.get(`${API_URL}/ReSendActivationEmail`, { params });
	}

	confirmEmail(id: string, idEmail: string, token: string) {

		const request = {
			userId: +id,
			userEmailId: +idEmail,
			token
		};

		return this.http.post<User>(`${API_URL}/ConfirmEmail`, request);
	}
	
	cancelEmail(id: string, idEmail: string, token: string) {

		const request = {
			userId: +id,
			userEmailId: +idEmail,
			token
		};

		return this.http.post<User>(`${API_URL}/CancelEmail`, request);
	}

	changeEmail(newEmail: string, currentPassword: string) {

		const activationLink = this.linkActivation();
		const deactivationLink = this.linkDeactivation();
		return this.http.post<{ userId: string, token: string }>(`${API_URL}/ChangeEmail`, { email: newEmail, password: currentPassword, activationLink: activationLink, deactivationLink: deactivationLink });
	}

	linkActivation() {

		const currentUrl = this.routerService.getCurrentUrl();
		const root = window.location.href.replace(currentUrl, '');
		return (root + this.router.createUrlTree(['auth/confirmar-correo/']).toString());
	}

	linkDeactivation() {

		const currentUrl = this.routerService.getCurrentUrl();
		const root = window.location.href.replace(currentUrl, '');
		return (root + this.router.createUrlTree(['auth/cancelar-correo/']).toString());
	}
	

	getUser():User {
		let result = new User();
		let currentUsr:string = localStorage.getItem(AuthService.CURRENT_USER);
		if (currentUsr) {
			Object.assign(result, JSON.parse(currentUsr));
		} else {
			result = null;
		}
		return result;
	}

	login(username: string, password: string) {
		const login = {
			username,
			password
		};
	
		this.http.post<User>(`${API_URL}`, login)
			.subscribe(data => {

				this.setToken(data.token);
				this.setRoleFromToken();
		
				if (this.validateUserAffiliates(data)) {
					this.handleSuccessfulLogin(data, username);
				} else {
					this.handleFailedLogin('No puede operar porque el usuario no posee afiliados asociados.');
				}
			}, error => {
				if (error.status === 401) 
				{   
					// Not authorized
					this.handleFailedLogin('Usuario o contraseña incorrecta.');
				}
				else {				
					this.handleFailedLogin('Hubo un error en el inicio de sesión. Por favor, inténtelo de nuevo.');
				}
			});
	}
	
	validateUserAffiliates(data: User): boolean {

		// sino posee afiliados el usuario no se puede loguear.
		if (data.affiliates == null) {
			return false;
		}
	
		return data.affiliates.length > 0;
	}
	
	handleSuccessfulLogin(data: User, username: string): void {
		this.saveCurrentUser(data);
		this.setUsernameFromToken();
		this.setEmailConfirmedFromToken();
		this.setEmailChangePendingFromToken();
		this.loggedIn = true;
		this._loggedIn.next(this.loggedIn);
		this.router.navigate(['inicio']);
		this.toaster.showToast('success', '', `Bienvenido ${username}`);
	}
	
	handleFailedLogin(message: string): void {
		this.loggedIn = false;
		this._loggedIn.next(this.loggedIn);
		this.router.navigate(['login']);
		this.toaster.showToast('error', '', message);
	}
	

	
	logout() {

		SwalFactory.openWarningWithTitleExitAndCancelBtn('¿Seguro que desea salir?')
			.then((isConfirm) => {
				if (isConfirm) {
					this.redirectToLogin();
					//Solicito las nuevas notificaciones al servidor.
					this.notificationService.notificationReaded.next(true);

				}
			});
	}

	redirectToLogin() {

		this.loggedIn = false;
		this._loggedIn.next(this.loggedIn);
		this.removeLocalStorage();
		this.router.navigate(['auth/inicio-sesion']);

		this._username.next(null);
		this._rolename.next(null);
		this._roledescription.next(null);
	}

	redirectToRegister() {

		this.loggedIn = false;
		this._loggedIn.next(this.loggedIn);
		this.removeLocalStorage();
		this.router.navigate(['auth/registro']);

		this._username.next(null);
		this._rolename.next(null);
		this._roledescription.next(null);

		
	}

	removeLocalStorage() {
		localStorage.removeItem('wc-token');
		localStorage.removeItem('currentAfiiliateCode');
		localStorage.removeItem('currentUser');
	}

	// resetPassword(email: string) {

	// 	return this.http.post(`${API_URL}/ResetPassword`, { email });
	// }

	resetPassword(username: string, token: string, Password: string) {

		return this.http.post(`${API_URL}/ResetPassword`, { username, token, Password });
	}

	requestResetPassword(email: string) {

		return this.http.post(`${API_URL}/RequestChangePassword`, { email });
	}

	changePassword(currentPassword: string, newPassword: string) {

		return this.http.post(`${API_URL}/ChangePassword`, { currentPassword, newPassword });
	}

	updateToken(token: string) {

		localStorage.setItem('token', token);
		this.setRoleFromToken();
		this.setUsernameFromToken();
		this.setEmailConfirmedFromToken();
	}

	updateLinkedAffiliates(affiliates: Affiliate[]) {

		const user: User = this.getUser();
		user.affiliates = affiliates;
		this.saveCurrentUser(user);

		this._affiliates.next(affiliates);
	}

	private getTokenExpiration() {

		const token: Token = JSON.parse(window.atob(localStorage.getItem('wc-token').split('.')[1]));
		return token.exp;
	}

	private setUsernameFromToken() {

		const token: Token = JSON.parse(window.atob(localStorage.getItem('wc-token').split('.')[1]));
		this._username.next(token.unique_name);
		let usr = this.getUser();
        if (usr)    
		{
				this._affiliates.next(usr.affiliates);
		}
		else {
			this._affiliates.next(null);
		}
	}

	private setRoleFromToken() {
		const token: Token = JSON.parse(window.atob(localStorage.getItem('wc-token').split('.')[1]));
		this.role = new Role();
		this._rolename.next(token.role);
		this._roledescription.next(token.roleDescription);

		if (Array.isArray(token.Function)) {

			token.Function.forEach(claim => {
				this.role.functions.push(claim);
			});

		} else if (token.Function) {

			this.role.functions.push(token.Function);
		}

		
	}


	private setEmailConfirmedFromToken() {

		const token: Token = JSON.parse(window.atob(localStorage.getItem('wc-token').split('.')[1]));
		this.emailConfirmed = token.EmailConfirmed?.toLowerCase() === 'true';
		this._emailConfirmed.next(this.emailConfirmed);
	}	
	
	private setEmailChangePendingFromToken() {

		const token: Token = JSON.parse(window.atob(localStorage.getItem('wc-token').split('.')[1]));
		this.emailChangePending = token.EmailChangePending !== "" ? token.EmailChangePending : "";
		this._emailChangePending.next(this.emailChangePending);
	}

	setEmailChangePending(email: string) {
        this._emailChangePending.next(email);
    }

	private saveCurrentUser(user:User) {

		localStorage.setItem(AuthService.CURRENT_USER, JSON.stringify(user));
	}	

	private setToken(token: string) {
		localStorage.setItem('wc-token', token);
	}
	
}
