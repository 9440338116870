import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Notification } from 'src/app/models/notification';
import { BehaviorSubject } from 'rxjs';

const API_URL = `${environment.apiUrl}/Notification`;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notificationReaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  notificationReaded$ = this.notificationReaded.asObservable();

  constructor(private http: HttpClient) { 
    
  }

  getAll() {
    return this.http.get<Notification[]>(API_URL);
  }

  getById(id: number) {
    return this.http.get<Notification>(`${API_URL}/${id}`);
  }

  getToReadById(id: number) {
    return this.http.get<Notification>(`${API_URL}/getToReadById/${id}`);
  }

  delete(id: number) {
    return this.http.delete<any>(`${API_URL}/deleteNotificationUser/${id}`);
  }
}
