

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class QueryStringService {
	
	private parameters: { [key: string]: string } = {};
	private url: string = "";

	constructor() { }

	public addParameter(paramName: string, paramValue: any): QueryStringService{

		this.parameters[paramName] = paramValue.toString();
		return this;
	}

	public addParameterIfNotNull(paramName: string, paramValue: any): QueryStringService {

		if (paramValue != null && paramValue != undefined && paramValue.toString().length > 0 ) {
			this.parameters[paramName] = paramValue.toString();			
		}
		
		return this;
	}

	public addUrl(url: string): QueryStringService {

		this.url = url;
		return this;
	}
	
	public build(): string {

		let result = this.url;

		if (result.length > 0) {
			result += "?"
		}

		for (const key in this.parameters) {

			if (Object.prototype.hasOwnProperty.call(this.parameters, key)) {

				result += `${key}=${this.parameters[key]}&`;				
			}
		}

		if (result.endsWith("&") || result.endsWith("?")) {
			
			result = result.substring(0, result.length - 1);
		}

		return result;
	}
}