import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { PageService } from 'src/app/core/paging/page.service';
import { DatatableBaseComponent } from '../datatable-base/datatable-base.component';
import { FormatField } from '../helpers/format-field-helper';

@Component({
	selector: 'app-datatable',
	templateUrl: './datatable.component.bp.html',
	styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent extends DatatableBaseComponent implements OnInit, OnChanges {
	@Input() add?: boolean = false;
	@Input() edit?: boolean = false;
	@Input() delete?: boolean = false;
	@Input() unlink?: boolean = false;
	@Input() select?: boolean = false;
	@Input() export?: boolean = false;
	@Input() addOne?: boolean = false;
	@Input() generateKey?: boolean = false;
	@Input() selectedAllIn?: boolean = false;
	@Output() addClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() editClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() deleteClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() unlinkClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() selectClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() exportClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() addOneClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() generateKeyClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() subDetailClick: EventEmitter<any> = new EventEmitter<any>();

	// Select
	selectedAll: boolean = false;
	selectedRows: any[] = [];
	rows: any[] = [];

	actions = false;
	subDetailIndex: number[] = [];

	constructor(pageService: PageService) { super(pageService); }

	ngOnChanges() {

		super.ngOnChanges();

		if (this.select) {

			this.rows = JSON.parse(JSON.stringify(this.tableData));
			this.selectedRows = [];
			this.selectedAll = this.selectedAllIn;
		}
	}

	ngOnInit() {

		if (this.fields.some(e => e.type == 'subreport')) {

			this.fields.filter(e => e.type == 'subreport').forEach(item => {
				this.subDetailIndex.push(this.fields.findIndex(e => e === item));
			});
		}

		if (this.edit || this.delete || this.detail || this.add || this.unlink) {

			if (!(this.fields[this.fields.length - 1].name === 'actions')) {

				this.actions = true;
				this.fields.push({
					title: '',
					name: 'actions'
				});
			}
		}
	}

	handleAddClick(row) {
		this.addClick.emit(row);
	}

	handleKeyClick(row) {
		this.generateKeyClick.emit(row);
	}

	handleEditClick(row) {
		this.editClick.emit(row);
	}

	handleDetailClick(row) {
		this.detailClick.emit(row);
	}

	handleDeleteClick(row) {
		this.deleteClick.emit(row);
	}

	handleUnlinkClick(row) {
		this.unlinkClick.emit(row);
	}

	handleSubDetailClick(row, col) {
		this.subDetailClick.emit({ row, col });
	}

	handleExportClick() {
		this.exportClick.emit(this.export);
	}

	handleAddOneClick() {
		this.addOneClick.emit(this.addOne);
	}

	selectAll(checkEvent) {

		if (checkEvent.target.checked) {

			this.selectedRows = [];
			this.rows.forEach(row => {
				row.checked = true;
				const selectedRow = this.tableData[this.rows.indexOf(row)];
				this.selectedRows.push(selectedRow);
			});

		} else {

			this.selectedRows = [];

			this.rows.forEach(row => {
				row.checked = false;
			});

		}

		this.selectClick.emit(this.selectedRows);
	}

	toggleSelect(clickedRow) {

		const row = this.rows[this.tableData.indexOf(clickedRow)];

		if (row.checked) {

			const index = this.selectedRows.findIndex(r => r === clickedRow);
			row.checked = false;

			this.selectedRows.splice(index, 1);

		} else {

			row.checked = true;
			this.selectedRows.push(clickedRow);
		}

		if (this.selectedRows.length !== this.rows.length) {

			this.selectedAll = false;
		}

		if (this.selectedRows.length === this.rows.length) {

			this.selectedAll = true;
		}

		this.selectClick.emit(this.selectedRows);
	}

	hasSubDetail(row: any, index: number) {

		var field = this.fields[index];

		if(field.type == 'json' && !row[field.name]){
				return false;
		}
		return this.subDetailIndex.some(e => e === index);
}

	formatProp(row: number, name: string, field: any, value) {

		const col = this.fields.findIndex(e => e.name === name);

		if (value && field) {
			return FormatField.formatProperty(row, field, value);
		}

		// if (this.fields[col].isBoolean) {
		//         return value;
		// }

		return value;
	}
	columnViewAmount(){
		return this.fields.filter(e => !e.isHidden).length;
	}
	type(row: number, name: string, field: any, value) {

		const col = this.fields.findIndex(e => e.name === name);

		if ((name === 'summ' || name === 'amount' || field.type === 'int' || field.type === 'decimal') && value !== null) {
			return "numeric";
		}

		return "text";
	}
}
