import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {
  }
  transform(value: string) {
    // let purgedHtml = value.split('/****ExcludeFromHtmlOnHeader-Start****/').shift() + value.split('/****ExcludeFromHtmlOnHeader-End****/').pop();
      //return this.sanitized.bypassSecurityTrustHtml(purgedHtml);
      return this.sanitized.bypassSecurityTrustHtml(value);
  }

}
