import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeviceTypesService } from './device-types.service';
import { DeviceOwnersService } from './device-owners.service';
import { Filter, FilterBase } from 'src/app/shared/filter/model/filter';

@Injectable({
    providedIn: 'root'
})
export class DevicesFilterService extends FilterBase {
    owners: any[] = [];
    types: any[] = [];

    private currentFilter: Filter = {
        filter: [
            { title: 'Número de Serie', value: '', name: 'serial' },
            //{ title: 'Tipo', listItems: this.types, value: 'Todos', name: 'type', type: 'List' },
            { title: 'Tipo', items: this.types, value: 'Todos', name: 'type' },
            { title: 'Número de Afiliado', value: '', name: 'codeCommerce' },
            { title: 'Razón Social', value: '', name: 'social' }
        ]
    };
    private _filterSource: BehaviorSubject<Filter | undefined> = new BehaviorSubject<Filter>(this.currentFilter);
    filter$ = this._filterSource.asObservable();
    prevFilter: Filter = {
        filter: [
            { title: 'Número de Serie', value: null, name: 'serial' },
            { title: 'Tipo', listItems: this.types, value: 'Todos', name: 'type', type: 'List' },
            { title: 'Número de Afiliado', value: null, name: 'codeCommerce' },
            { title: 'Razón Social', value: null, name: 'social' }
        ]
    };

    constructor(private typeService: DeviceTypesService, private ownerService: DeviceOwnersService) {
        super();
        this.loadTypes();
        this.loadOwners();
    }

    loadTypes() {
        this.typeService.types$.subscribe(types => {


            types.forEach(type => {
                this.types.push(type.name);


            });
        });
    }
    loadOwners() {
        this.ownerService.owners.subscribe(owners => {
            owners.forEach(owner => {
                this.owners.push(owner.name);
            });
        });
    }
    cleanFilter() {
        this._filterSource.next({
            filter: [
                { title: 'Número de Serie', value: '', name: 'serial' },
                { title: 'Tipo', items: this.types, value: 'Todos', name: 'type' },
                { title: 'Número de Afiliado', value: '', name: 'codeCommerce' },
                { title: 'Razón Social', value: '', name: 'social' }
            ]
        });

    }
    cleanPrevFilter() {
        this.prevFilter = {
            filter: [
                { title: 'Número de Serie', value: null, name: 'serial' },
                { title: 'Tipo', items: this.types, value: 'Todos', name: 'type' },
                { title: 'Número de Afiliado', value: null, name: 'codeCommerce' },
                { title: 'Razón Social', value: null, name: 'social' }
            ]
        };
    }
    getValue() {
        return this._filterSource.getValue();
    }
}

