import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaymentMethod } from 'src/app/models/payment-method';

const API_URL = `${environment.apiUrl}/BioPos`;

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {
    private paymentMethods = [];
    private _paymentMethods: BehaviorSubject<PaymentMethod[]> = new BehaviorSubject<PaymentMethod[]>(this.paymentMethods);
    paymentMethods$ = this._paymentMethods.asObservable();

    constructor(private http: HttpClient) { }

    getPaymentMethods() {
        return this.http.get<any>(`${API_URL}/GetAllPaymentMethod`);
    }
    
    setPaymentMethods(paymentMethods) {
        this._paymentMethods.next(paymentMethods);
    }

    getValue() {
        return this._paymentMethods.getValue();
    }
}
