<div class="card flex-row align-items-center align-items-stretch border-0">
    <div class="col-4 d-flex align-items-center bg-green justify-content-center rounded-left">
        <div class="text-center">
            <div now="" format="MMMM" class="text-sm text-uppercase"></div><br>
            <div now="" format="D" class="h2 mt-0"></div>
        </div>
    </div>
    <div class="col-8 py-3 rounded-right text-center">
        <div now="" format="dddd" class="text-uppercase"></div><br>
        <div now="" format="h:mm" class="h2 mt-0"></div>
        <div now="" format="a" class="text-muted text-sm"></div>
    </div>
</div>