import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PagedResult } from 'src/app/models/paged-result';

@Injectable({
    providedIn: 'root'
})
export class PageService {
    private page = new PageState();
    private _page: BehaviorSubject<PageState> = new BehaviorSubject<PageState>(this.page);
    page$ = this._page.asObservable();

    private detailPage = new PageState();
    private _detailPage: BehaviorSubject<PageState> = new BehaviorSubject<PageState>(this.detailPage);
    detailPage$ = this._detailPage.asObservable();

    constructor() { }

    setPage(page: PageState) {
        this.page = page;
        this._page.next(this.page);
    }
    setDetailPage(page: PageState) {
        this.detailPage = page;
        this._detailPage.next(this.detailPage);
    }
    setResultPage(pagedResult: PagedResult<any>) {
        this.page.totalItems = pagedResult.rowQuantity;
        this.page.page = pagedResult.currentPage;
        this.page.prevPage = pagedResult.currentPage;
        this.page.prevItemsPerPage = pagedResult.pageSize;
        this.setPage(this.page);
    }
    setDetailResultPage(pagedResult: PagedResult<any>) {
        this.detailPage.totalItems = pagedResult.rowQuantity;
        this.detailPage.page = pagedResult.currentPage;
        this.detailPage.prevPage = pagedResult.currentPage;
        this.setDetailPage(this.detailPage);
    }
    cleanPage() {
        const newPage = new PageState();
        newPage.itemsPerPage = this.page.itemsPerPage;
        newPage.prevItemsPerPage = this.page.prevItemsPerPage;
        this.page = newPage;
        this._page.next(this.page);
    }
    cleanDetailPage() {
        this.detailPage = new PageState();
        this._detailPage.next(this.detailPage);
    }
    rollbackPageState() {
        this.page.itemsPerPage = this.page.prevItemsPerPage;
        this.page.page = this.page.prevPage;
        this._page.next(this.page);
    }
    rollbackDetailPageState() {
        this.detailPage.page = this.detailPage.prevPage;
        this._detailPage.next(this.detailPage);
    }
    getValue() {
        return this._page.getValue();
    }
    getDetailValue() {
        return this._detailPage.getValue();
    }
}
class PageState {
    page: number = 1;
    prevPage: number;
    itemsPerPage: number = 10;
    prevItemsPerPage: number = this.itemsPerPage;
    totalItems: number;
    sortProp: string = '';
    sortDirection: string = '';
    sortedAsc: boolean = false;
    sortedDesc: boolean = false;
}
