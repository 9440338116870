<!-- START Top Navbar-->
<nav class="navbar topnavbar d-flex" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand align-middle">
            <div class="brand-logo">
                <img class="img-fluid d-md-none" src="assets/img/BiopagoBDV-logo.png" alt="App Logo"
                    style="height: 32px;" />
                <img class="img-fluid d-none d-md-block d-lg-block" src="assets/img/BiopagoBDV-red-logo.png"
                    alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" src="assets/img/bpbdv-logo.png" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->

    <!-- START Left navbar-->
    <div class="navbar-nav mr-auto flex-row flex-fill no-backgorund" style="background-position-x: right;background-repeat: no-repeat;">
        <div *ngIf="loggedIn" class="nav-item">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link pointer d-none d-md-block d-lg-block d-xl-block burger" trigger-resize=""
                (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                <em class="fas fa-bars"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="nav-link sidebar-toggle d-md-none burger"
                (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
                <em class="fas fa-bars"></em>
            </a>
        </div>
        <!-- START title-->
        <div *ngIf="title !== '' && title !== undefined" class="nav-item ml-3 d-none d-md-block">
            <h2>
                {{title | titleCase}}
            </h2>
        </div>
        <!-- END title-->
        <!-- START description-->
        <div *ngIf="description !== '' && description !== undefined" class="nav-item d-none d-md-block">
            <a class="nav-link" placement="auto" tooltip="{{description}}"><em
                    class="fa-lg fas fa-info-circle"></em></a>
        </div>
        <!-- END description-->
    </div>
    <!-- END Left navbar-->

    <!-- START Right Navbar-->
    <ul class="navbar-nav flex-row align-items-center bg-white-lg-none">
        <li class="nav-item dropdown dropdown-list" dropdown *ngIf="loggedIn">
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret pointer" dropdownToggle>
                <em class="icon-bell"></em>
                <span class="badge badge-danger" style="background-color: #FF9900"
                    *ngIf="hasNewNotifications">{{totalNewNotificationsCount}}</span>
            </a>
            <!-- START Dropdown menu-->
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
                <div class="dropdown-item">
                    <!-- START list group-->
                    <div class="list-group">
                        <!-- list item-->
                        <div class="list-group-item list-group-item-action"
                            *ngFor="let notification of newNotifications">
                            <div class="media">
                                <div class="align-self-start mr-2">
                                    <em class="icon-bell" style="color: #FF9900;"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">{{notification.title}}</p>
                                    <!-- <p class="m-0 text-muted text-sm">{{notification.description}}</p> -->
                                </div>
                            </div>
                        </div>
                        <!-- last list item-->
                        <div class="list-group-item list-group-item-action">
                            <span class="d-flex align-items-center pointer"
                                [routerLink]="['notificaciones/mis-notificaciones']">
                                <span class="text-sm">Ir a notificaciones</span>
                                <span class="badge badge-danger ml-auto"
                                    style="background-color: #FF9900">{{totalNotificationsCount}}</span>
                            </span>
                        </div>
                        <!-- <div _ngcontent-bgr-c93="" class="list-group-item list-group-item-action">
                        <span _ngcontent-bgr-c93="" class="d-flex align-items-center">
                            <span _ngcontent-bgr-c93="" class="text-sm" routerLink="/notificaciones/mis-notificaciones">Ir a notificaciones</span>
                            <span _ngcontent-bgr-c93="" class="badge badge-danger ml-auto" style="background-color: #FF9900">{{totalNotificationsCount}}</span>
                        </span>
                        </div> -->
                    </div>
                    <!-- END list group-->
                </div>
            </div>
            <!-- END Dropdown menu-->
        </li>
        <!-- <li _ngcontent-bgr-c93="" dropdown="" class="nav-item dropdown dropdown-list open show">
            <a _ngcontent-bgr-c93="" dropdowntoggle="" class="nav-link dropdown-toggle dropdown-toggle-nocaret" aria-haspopup="true" aria-expanded="true">
                <em _ngcontent-bgr-c93="" class="icon-bell"></em>
                <span _ngcontent-bgr-c93="" class="badge badge-danger" style="background-color: #f7a104" *ngIf="hasNewNotifications">{{totalNotificationsCount}}</span>
            </a>
            <div _ngcontent-bgr-c93="" class="dropdown-menu dropdown-menu-right animated flipInX show" style="inset: 100% 0px auto auto; transform: translateY(0px);">
                <div _ngcontent-bgr-c93="" class="dropdown-item">
                    <div _ngcontent-bgr-c93="" class="list-group">
                        <div _ngcontent-bgr-c93="" class="list-group-item list-group-item-action" *ngFor="let notification of newNotifications">
                            <div _ngcontent-bgr-c93="" class="media">
                                <div _ngcontent-bgr-c93="" class="align-self-start mr-2">
                                    <em _ngcontent-bgr-c93="" class="icon-bell" style="color: orange;"></em>
                                </div>
                                <div _ngcontent-bgr-c93="" class="media-body">
                                    <p _ngcontent-bgr-c93="" class="m-0">{{notification.title}}</p>
                                    <p _ngcontent-bgr-c93="" class="m-0 text-muted text-sm">{{notification.description}}</p>
                                </div>
                            </div>
                        </div>

                        <div _ngcontent-bgr-c93="" class="list-group-item list-group-item-action">
                            <span _ngcontent-bgr-c93="" class="d-flex align-items-center">
                                <span _ngcontent-bgr-c93="" class="text-sm" routerLink="/notificaciones/mis-notificaciones">Ir a notificaciones</span>
                                <span _ngcontent-bgr-c93="" class="badge badge-danger ml-auto" style="background-color: #f7a104">{{totalNotificationsCount}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </li> -->
        <li class="nav-item d-none d-lg-block">
            <a class="nav-link pointer" #fsbutton (click)="toggleFullScreen($event)">
                <em class="fa fa-expand"></em>
            </a>
        </li>
        <!-- <li class="nav-item d-none d-lg-block">
            <a class="nav-link pointer align-self-middle p-0 text-center" target="_blank"
                href="http://www.biopagobdv.com/">
                <img class="w-50" src="assets\img\bpbdv-white-logo.png" alt="ExCle" />
            </a>
        </li> -->
        <li class="nav-item d-none d-lg-block">
            <a class="nav-link pointer align-self-middle p-0 pr-2" target="_blank"
                href="https://www.bancodevenezuela.com/">
                <img src="assets\img\bdv-isologo.png" alt="BiopagoBDV" />
            </a>
        </li>

        <li class="nav-item d-none d-md-block d-lg-none border-left border-white">
            <a class="nav-link pointer align-self-middle p-0 pr-2 pl-2" target="_blank"
                href="https://www.bancodevenezuela.com/">
                <img src="assets\img\bdv-isologo.png" alt="BiopagoBDV" />
            </a>
        </li>

    </ul>
    <!-- END Right Navbar-->

    <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>

</nav>
<!-- END Top Navbar-->