import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Filter } from 'src/app/shared/filter/model/filter';
import { FilterService } from './filter.service';
import { PagedResult } from 'src/app/models/paged-result';
import { PageService } from 'src/app/core/paging/page.service';
import { Transaction } from 'src/app/models/transaction';
import { QueryStringService } from 'src/app/shared/infrastructure/query-string.service';
import { SelectedService } from 'src/app/transactions/selected/selected.service';
import { ToasterNotificationService } from 'src/app/core/toaster/toaster-notification.service';
import { TransactionDetail } from 'src/app/models/transaction-detail';
import { ProfileService } from '../profile/profile.service';

const API_URL = `${environment.apiUrl}/Transaction`;

@Injectable({
    providedIn: 'root'
})
export class TransactionService {

    private transactions: TransactionDetail[] = [];
    private _transactions: BehaviorSubject<TransactionDetail[]> = new BehaviorSubject<TransactionDetail[]>(this.transactions);
    transactions$ = this._transactions.asObservable();

    constructor(
        private http: HttpClient,
        private pageService: PageService,
		private selectedService: SelectedService,
		private toasterService: ToasterNotificationService,
        private filterService: FilterService,
        private profileService: ProfileService) { }

    getGroupedTransactions() {
        return this.http.get<{ paymentGroupName: string, color: string, numberOfTransactions: number }[]>(API_URL+ '/' + this.profileService.getCurrentAffiliateCode());
    }

    GetLastTransaction() {
        return this.http.get<Transaction[]>(API_URL+ '/GetLastTransaction/' + this.profileService.getCurrentAffiliateCode());
    }
    
    getTransactions(filter: Filter) : Observable<PagedResult<TransactionDetail>> {
		const pageState = this.pageService.getValue();

		const queryString = new QueryStringService()
								.addUrl(API_URL+ '/GetByFilter')
								.addParameterIfNotNull("createdOnFrom", filter.filter[0].value)
								.addParameterIfNotNull("createdOnTo", filter.filter[1].value)
								.addParameterIfNotNull("paymentGroupId", filter.filter[2].value)
								.addParameterIfNotNull("paymentMethodId", filter.filter[3].value)
                                .addParameterIfNotNull("batch", filter.filter[4].value)
								.addParameterIfNotNull("terminal", filter.filter[5].value)
								.addParameterIfNotNull("affiliateNumber", this.profileService.getCurrentAffiliateCode())
								.addParameterIfNotNull("result", 'ok')
                                .addParameterIfNotNull("pageNumber", pageState.page)
								.addParameterIfNotNull("pageSize", pageState.itemsPerPage)
								.addParameterIfNotNull("sortProp", pageState.sortProp)
								.addParameterIfNotNull("sortDirection", pageState.sortDirection)
								.build();
    
		return this.http.get<PagedResult<TransactionDetail>>(queryString);
    }
    
    getTransactionsNok(filter: Filter) : Observable<PagedResult<TransactionDetail>> {
		const pageState = this.pageService.getValue();

		const queryString = new QueryStringService()
								.addUrl(API_URL+ '/GetByFilter')
								.addParameterIfNotNull("createdOnFrom", filter.filter[0].value)
								.addParameterIfNotNull("createdOnTo", filter.filter[1].value)
								.addParameterIfNotNull("citizen", filter.filter[2].value)
								.addParameterIfNotNull("terminal", filter.filter[3].value)
								.addParameterIfNotNull("affiliateNumber", this.profileService.getCurrentAffiliateCode())
								.addParameterIfNotNull("result", 'nok')
								.addParameterIfNotNull("pageNumber", pageState.page)
								.addParameterIfNotNull("pageSize", pageState.itemsPerPage)
								.addParameterIfNotNull("sortProp", pageState.sortProp)
								.addParameterIfNotNull("sortDirection", pageState.sortDirection)
								.build();
    
		return this.http.get<PagedResult<TransactionDetail>>(queryString);
    }
    
    

	getTransactionById(transactionId: number) {

        return this.http.get<TransactionDetail>(`${API_URL}/GetById/${transactionId}`);
    }

	setValue(transactions: Array<any>) {
		
        this.transactions = transactions;
        this._transactions.next(this.transactions);
	}
	
	cleanTransactions() {
		
        this.transactions = [];
        this._transactions.next(this.transactions);
	}

	GetTransactionByFilter(transactionNumber: string, transactionAccount: string): Observable<Transaction>  {
		
		return this.http.get<Transaction>(`${API_URL}/Numbers/${transactionNumber}/Accounts/${transactionAccount}`);
	}

	LinkTransaction(transactionId: number) {

		return this.http.post(`${API_URL}/${transactionId}/Users/Current/link`, null);
	}

	getTransactionByUser(): Observable<Transaction[]>{

		return this.http.get<Transaction[]>(`${API_URL}/Users/Current`);		
	}

	mapTransaction(transactionDetail: TransactionDetail): any {

        const transaction = {
            id: transactionDetail.transactionId,
            // codeCommerce: transactionDetail.codeCommerce,
            // social: transactionDetail.social,
            // commerceName: transactionDetail.corporationName,
            // rif: transactionDetail.rif,
            // categoryId: transactionDetail.categoryId,
            createdOn: transactionDetail.createdOn
        };

        return transaction;
    }

}
