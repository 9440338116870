import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    changeAffiliate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    changeAffiliate$ = this.changeAffiliate.asObservable();
    affiliateActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    affiliateActive$ = this.affiliateActive.asObservable();

    constructor() { }

    setCurrentAffiliateCode(affiliateCode: string){
        localStorage.setItem('currentAfiiliateCode', affiliateCode);
    }

    getCurrentAffiliateCode(){
        return localStorage.getItem('currentAfiiliateCode');
    }

}
