import { Component, OnInit } from '@angular/core';
import { TransactionService } from 'src/app/services/transactions/transaction.service';
import { PageState } from 'src/app/core/paging/pageState';
import * as configData from './transaction-widget-config.json';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { FormatField } from 'src/app/shared/helpers/format-field-helper';
import { values } from 'lodash';



@Component({
  selector: 'app-transactions-widget',
  templateUrl: './transactions-widget.component.html',
  styleUrls: ['./transactions-widget.component.scss']
})
export class TransactionsWidgetComponent implements OnInit {

  reportName: string;
  data: any[] = [];
  isLoaded: boolean;

  message: string;

  isGroupping: boolean;


  constructor(
    private reportService: TransactionService, private profileService: ProfileService) { }

  ngOnInit() {

    this.isLoaded = true;

    this.profileService.changeAffiliate$.subscribe((val) => {
      this.refresh();
    });
  }

  refresh() {
    let ps = new PageState();
    ps.itemsPerPage = configData.default.itemsPerPage;
    ps.sortProp = configData.default.sortProp;
    ps.sortDirection = configData.default.sortDirection;

    this.reportName = configData.default.reportName;

    this.isLoaded = false;
    this.data = [];

    this.reportService.GetLastTransaction().subscribe(      
      data => {
                  this.data = this.groupBy(data,(item) => item.batch);
                  this.message = "Aún no posee actividad."
      },
      error => {
        this.message = "Ha ocurrido un error. Si el mismo persiste, por favor contactese con el área de Soporte Técnico."
      }).add(() => {
        this.isLoaded = true;
      });
  }

  groupBy<T, K>(list: T[], getKey: (item: T) => K) {
    const map = new Map<K, T[]>();
    list.forEach((item) => {
        const key = getKey(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return Array.from(map.values());
}

  formatProp(value) {

    var field: any = {};
    field.name = "amount";

    if (value) {
      value = FormatField.formatProperty(0, field, value);
    }
    return value;
  }
}