export class FilterBase {
    prevFilter: Filter;
    setPrevFilter(prevFilter: Filter) {
        this.prevFilter = prevFilter;
    }
}

export interface Filter {
    filter: FilterField[];
}

export interface FilterField {
    title: string;
    value: string;
    name: string;
    items?: string[];
    listItems?: CboFilterField[];
    type?: string;
    required?: boolean;
    placeholder?: string;
    max?:number;
    dateDefault?:string;
    maxDate?:string;
    minDate?:string;
    readonly?: boolean;
    regExpType?: string;

}

export interface CboFilterField {
    id: string;
    description: string;
}
