import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { AppContextService } from '../core/context/app-context.service';
import { delay, take, skipWhile } from 'rxjs/operators';
import { ToasterConfig, ToasterService } from 'angular2-toaster';
import { ToasterNotificationService } from '../core/toaster/toaster-notification.service';
import { RouterService } from '../core/router/router.service';
import { Router, NavigationEnd } from '@angular/router';
import { HeaderService } from '../core/header/header.service';
import { remove } from 'lodash';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    loggedIn: boolean;
    showLoader: boolean;
    showFooter: boolean;
    title: string;
    description: string;
    chatNode:any;
    suscriptionToaster: any;
    suscriptionLoader: any;

    // TOASTER
    toaster: any;
    toasterconfig: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-top-center',
        showCloseButton: true,
        preventDuplicates: false
    });

    constructor(
        private auth: AuthService,
        private appContext: AppContextService,
        private toasterService: ToasterService,
        private header: HeaderService,
        private toasterNotification: ToasterNotificationService,
        private router: Router) {
        this.router.events.subscribe(event => {

            if (event instanceof NavigationEnd
                && (event.url.startsWith('/inicio')
                    || event.url.startsWith('/auth/inicio-sesion'))) {

                this.showFooter = true;

            } else {

                this.showFooter = false;                
            }
        });
    }

    ngOnInit() {

        this.auth.loggedIn$.subscribe(data => {
            this.loggedIn = data;
            // if(!data){
            //     this.unLoadCostumerChat();
            //     console.log("loged out");
            // }
        });

        this.suscriptionLoader = this.appContext.asyncCall$.pipe(
            // ValueChangedAfterDetection Error Fix
            delay(0)
        )
            .subscribe(data => {
                this.showLoader = data;
            });

        this.suscriptionToaster = this.toasterNotification.toaster$
            .pipe(
                skipWhile(t => t == null || t === undefined)
            )
            .subscribe(toast => {
                this.toaster = toast;
                this.pop();
            });

        this.header.title.subscribe(title => {

            if (title !== undefined) {

                this.title = title.title;
                this.description = title.description;
            }
        });

        // this.loadCostumerChat();
    }

    pop() {

        this.toasterService.pop(this.toaster.type, this.toaster.title, this.toaster.text);
    }

    ngOnDestroy() {
        this.suscriptionToaster.unsubscribe();
        this.suscriptionLoader.unsubscribe();
    }

    loadCostumerChat() {
        // let proto = 'https:';
        // this.chatNode = document.createElement('script');
        // this.chatNode.type = 'text/javascript';
        // this.chatNode.async = true;				
        // this.chatNode.src = proto + '//webchat-i6.biopagobdv.com/v3/click_to_chat?token=78C80B2E84DFF21DA579AEA1AE86FE1E';
        // document.getElementById("ChatContainer").appendChild(this.chatNode);
    }

    unLoadCostumerChat(){
        document.getElementById("ChatContainer").remove();
    }
}
