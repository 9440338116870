import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DevicesFilterService } from './filter.service';
import { DeviceTypesService } from './device-types.service';
import { Device } from 'src/app/models/device';
import { ProfileService } from '../profile/profile.service';
import { BehaviorSubject } from 'rxjs';

const API_URL = `${environment.apiUrl}/Device`;

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    private affiliateDevices = [];
    private _affiliateDevices: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>(this.affiliateDevices);
    affiliateDevices$ = this._affiliateDevices.asObservable();

    constructor(
        private http: HttpClient,
        private filterService: DevicesFilterService,
        private typeService: DeviceTypesService,
        private profileService: ProfileService) { }

    public getDevices() {
        return this.http.get<Device[]>(`${API_URL}/${this.profileService.getCurrentAffiliateCode()}`);
    }

    setAffiliateDevices(affiliateDevices) {
        this._affiliateDevices.next(affiliateDevices);
    }

    getValue() {
        return this._affiliateDevices.getValue();
    }
}