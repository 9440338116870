import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Directive({
    selector: '[appIconPermission]'
})
export class IconPermissionDirective implements OnInit {
    @Input('appIconPermission') action: string;

    constructor(private el: ElementRef, private renderer: Renderer2, private route: ActivatedRoute, private auth: AuthService) { }

    ngOnInit() {
        const currentRole = this.auth.getRole;
        this.route.data.subscribe(data => {
            const role = data[this.action];
            if (role && !currentRole.functions.some(e => e === role)) {
                this.renderer.addClass(this.el.nativeElement, 'disabled');
            }
        })
    }


}
