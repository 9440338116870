import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ComboItem } from 'src/app/shared/filter/filter';
import { Filter, FilterBase } from 'src/app/shared/filter/model/filter';
import { PaymentGroupService } from '../reports/payment-group.service';
import { PaymentMethodService } from '../reports/payment-method.service';
import { DeviceService } from '../devices/device.service';
import * as moment from 'moment';


@Injectable({
    providedIn: 'root'
})
export class FilterService extends FilterBase {
    paymentGroups = [];
    paymentMethods = [];
    affiliateDevices = [];


    private currentFilter: Filter = {
        filter: this.getFilters()
    };
    private _filter: BehaviorSubject<Filter> = new BehaviorSubject<Filter>(this.currentFilter);
    filter$ = this._filter.asObservable();

    prevFilter = {
        filter: this.getFilters()
    };

    constructor(private paymentMethodService: PaymentMethodService,
                private paymentGroupService: PaymentGroupService,
                private deviceService: DeviceService
                ) {
        super();
        this.loadPaymentGroups();
        this.loadPaymentMethods();
        this.loadAffiliateDevices();
    }

    loadPaymentGroups() {
        this.paymentGroupService.paymentGroups$.subscribe(paymentGroups => {
            var newList = [];
            paymentGroups.forEach(paymentGroup => {
                newList.push( new ComboItem (paymentGroup.id ,paymentGroup.name ));
            });
            this.paymentGroups = newList;
            this.cleanFilter();
        });
    }

    loadPaymentMethods() {
        this.paymentMethodService.paymentMethods$.subscribe(paymentMethods => {
            var newList = [];
            paymentMethods.forEach(paymentMethod => {
                newList.push( new ComboItem (paymentMethod.id ,paymentMethod.name ));
            });
            this.paymentMethods = newList;
            this.cleanFilter();
        });
    }

    loadAffiliateDevices() {
        this.deviceService.affiliateDevices$.subscribe(affiliateDevices => {
            var newList = [];
            affiliateDevices.forEach(affiliateDevice => {
                newList.push( new ComboItem (affiliateDevice.serial , affiliateDevice.serial.toString() ));
            });
            this.affiliateDevices = newList;
            this.cleanFilter();
        });


    }

    cleanFilter() {
        this._filter.next({
            filter: this.getFilters()
        });
    }
    getValue() {
        return this._filter.getValue();
    }

    getFilters() {
        return [
            { title: 'Fecha Desde', value: moment().subtract(7, 'day').format('YYYY-MM-DD'), name: 'createdOnFrom', type: 'DateFrom', placeholder: 'Desde', minDate: moment().subtract(90, 'day').format('YYYY-MM-DD'), maxDate:moment().format('YYYY-MM-DD')  },
            { title: 'Fecha Hasta', value: moment().format('YYYY-MM-DD'), name: 'createdOnTo', type: 'DateTo', placeholder: 'Hasta', minDate: moment().subtract(90, 'day').format('YYYY-MM-DD'), maxDate:moment().format('YYYY-MM-DD')  },
            { title: 'Emisor', value: null, name: 'paymentGroup', type:'List', listItems: this.paymentGroups, placeholder: 'Todos'},
            { title: 'Instrumento', value: null, name: 'paymentMethod', type:'List', listItems: this.paymentMethods, placeholder: 'Todos'},
            { title: 'N° Lote', value: null, name: 'batch', placeholder: 'N° Lote' },
            { title: 'Equipo', value: null, name: 'terminal', type:'ListAddTag', listItems: this.affiliateDevices, placeholder: 'Todos'},

     ];
    }
}
