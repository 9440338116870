import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

import { UserblockService } from './userblock.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;
    username: string;
    rolename: string;
    roledescription: string;
    constructor(public userblockService: UserblockService, public auth: AuthService) {
	    this.auth.username$.subscribe(data => this.username = data);
        this.auth.rolename$.subscribe(data => this.rolename = data);
        this.auth.roledescription$.subscribe(data => this.roledescription = data);
        this.user = {
            picture: 'assets/img/user/01.jpg'
        };
    }

    ngOnInit() {
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
