import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { FilterField } from './model/filter';
import { DateValidator } from '../custom-validators/date-validator';
import { ComboItem } from './filter';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges {
    @Input() filters: Array<FilterField>;
    @Output() filterSearch: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
    @Output() cleanFilter: EventEmitter<any> = new EventEmitter<any>();
    filterForm: FormGroup;
    mandatoryFilters : boolean;

    constructor(private formBuilder: FormBuilder) { }

      addTag(tag: any) {
       return new ComboItem (tag ,tag );
      }

      onChange(item: any, filterName: string) {
        this.filterForm.controls[filterName].setValue(item.description);

      }

    ngOnChanges() {
        this.filterForm = this.formBuilder.group({});
        if (this.filters) {
            this.filters.forEach(item => {
                if (!this.filterForm.controls[item.name]) {
                        this.filterForm.addControl(item.name, new FormControl(item.value));
                }
                else {
                    this.filterForm.controls[item.name].setValue(item.value);
                }
               
                this.addValidators(this.filterForm.controls[item.name], item);
            });
        }
        
    }
    ngOnInit() {

    }

    clickFilter(search=false){
        const divFilters = document.getElementById("filtersForm");
        const btnFilters = document.getElementById("filtersBtn");
        
        if ((divFilters.classList.contains('d-none')) && !search) {
            // ✅ Shows element if hidden
            divFilters.classList.remove('d-none');
            btnFilters.textContent = 'Ocultar Filtros';
          } else {
            // ✅ Hides element if shown
            divFilters.classList.add('d-none');
            btnFilters.textContent = 'Mostrar Filtros';
          }
      };

    search() {
        if(this.filterForm.valid )
        {
            this.filters.forEach(item => {
                // value must be string even if empty
                const val = this.filterForm.value[item.name];
                item.value = val !== null && val !== undefined ? val.toString() : '';
                if (item.regExpType){ 
                    item.value = this.regularExpresionReplacement(item);
                }
            });
            this.filterSearch.emit(this.filters);
            // despues de buscar oculta nuevamente los filtros
            this.clickFilter(true);
        }
    }


    private regularExpresionReplacement(item: FilterField): string {
        let formattedValue = item.value;
        
        if (item.regExpType == "NationalId") {
            formattedValue = item.value.replace(/[\s-]/g, '').toUpperCase();

            if (formattedValue.length > 1) {
                formattedValue = formattedValue.substring(0, 1) + ' ' + formattedValue.substring(1);
            }
        }
        return formattedValue;
    }

    clean() {
        this.cleanFilter.emit();
    }
    validateDates(name: string) {
        const root = name.includes('To') ? name.replace('To', '') : name.replace('From', '');
        const dateToControl = this.filterForm.controls[root + 'To'];
        const dateFromControl = this.filterForm.controls[root + 'From'];

        if (dateToControl && dateFromControl &&
            (dateToControl.value !== null && dateToControl.value !== '') &&
            (dateFromControl.value !== null && dateFromControl.value !== '')
        ) {
            dateToControl.setValidators(DateValidator.dateMinimum(dateFromControl.value));
            dateToControl.updateValueAndValidity();

            dateFromControl.setValidators(DateValidator.dateMaximum(dateToControl.value));
            dateFromControl.updateValueAndValidity();
        }
    }
    validateNumerics(name: string) {
        const root = name.includes('To') ? name.replace('To', '') : name.replace('From', '');
        const numericToControl = this.filterForm.controls[root + 'To'];
        const numericFromControl = this.filterForm.controls[root + 'From'];

        if (numericToControl && numericFromControl &&
            (numericToControl.value !== null && numericToControl.value !== '') &&
            (numericFromControl.value !== null && numericFromControl.value !== '')
        ) {
            numericToControl.setValidators(Validators.min(numericFromControl.value));
            numericToControl.updateValueAndValidity();

            numericFromControl.setValidators(Validators.max(numericToControl.value));
            numericFromControl.updateValueAndValidity();
        }
    }
    private addValidators(control: AbstractControl, filter: FilterField) {
        const validators = [];
        if (filter.required) {
            validators.push(Validators.required);
            this.mandatoryFilters = true;
        }
        if (filter.type === 'Numeric') {
            validators.push(Validators.min(0));
            if(filter.max!=null)
                validators.push(Validators.max(filter.max));
        }
        control.setValidators(validators);
        control.updateValueAndValidity();
    }


    onKeyDown(event: KeyboardEvent) {
        event.preventDefault();
      }
}
