import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Router } from '@angular/router';
const screenfull = require('screenfull');

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { HeaderService } from 'src/app/core/header/header.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notifications/notification.service.service';
import { Notification } from 'src/app/models/notification';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.bp.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    navCollapsed = true; // for horizontal layout
    menuItems: Array<any> = []; // for horizontal layout
    router: Router;

    title: string;
    description: string;

    loggedIn: boolean;
    totalNotificationsCount: number = 0;
    totalNewNotificationsCount: number = 0;
    newNotifications: Notification[] = [];
    hasNewNotifications: boolean  = false;

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

    constructor(
        public menu: MenuService,
        public userblockService: UserblockService,
        public settings: SettingsService,
        public injector: Injector,
        public header: HeaderService,
        private auth: AuthService,
        private notificationService: NotificationService) {

        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout

    }

    ngOnInit() {

        this.isNavSearchVisible = false;

        this.notificationService.notificationReaded$.subscribe((val)=>{
            this.checkNewNotifications();
        });
        var ua = window.navigator.userAgent;
        if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }

        // Switch fullscreen icon indicator
        const el = this.fsbutton.nativeElement.firstElementChild;
        screenfull.on('change', () => {
            if (el)
                el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        });

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });

        this.header.title.subscribe(title => {
            if (title !== undefined) {
                this.title = title.title;
                this.description = title.description;
            }
        });

        this.auth.loggedIn$.subscribe(data => {
            this.loggedIn = data;

            //this.checkNewNotifications();
        });

    }

    checkNewNotifications(){
        this.totalNotificationsCount = 0;
        this.totalNewNotificationsCount = 0;
        this.hasNewNotifications = false;
        if( this.loggedIn)
        {
            this.notificationService.getAll().subscribe(data => {
                this.newNotifications = [];
                data.forEach(d=>{
                    if(this.newNotifications.length < 5 && !d.read)
                    {
                        this.newNotifications.push({
                            id: d.id,
                            title: d.title,
                            description: d.description,
                            template: d.template,
                            createdOn: d.createdOn,
                            read: d.read,
                        });
                    }
                });
    
                this.totalNotificationsCount = data.length;
                this.totalNewNotificationsCount = this.newNotifications.length;
                this.newNotifications.length > 0 ? this.hasNewNotifications = true : this.hasNewNotifications = false;
                
            });
        }


    };

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.isEnabled) {
            screenfull.toggle();
        }
    }

    redirectToLogin() {
        this.auth.redirectToLogin();
    }
}
