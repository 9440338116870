import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth/auth.service';
import { ToasterNotificationService } from 'src/app/core/toaster/toaster-notification.service';
import { RouterService } from 'src/app/core/router/router.service';
import { ActivatedRoute } from '@angular/router';

const genericErrorMessage = 'Algo salió mal :(';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthService,
        private routerService: RouterService,
        private route: ActivatedRoute,
        private toaster: ToasterNotificationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {   
                // auto logout if 401 response returned from api
                const login = this.routerService.getCurrentUrl().split('/').find(e => e.startsWith('inicio-sesion'));
                const confirm = this.routerService.getCurrentUrl().split('/').find(e => e.startsWith('confirmar-correo'));
                if (login) {
                    // this.toaster.showToast('error', '', 'Usuario o contraseña incorrectos');
                } 
                else if (err.error.status === 405) {
                    // auto logout if 405 response
                    if (!confirm){
                        this.toaster.showToast('error', '', err.error.detail);
                        }
                }
                if (!confirm && !login){
                    setTimeout(() => {
                        this.authenticationService.redirectToLogin(); // Redirige a la página de inicio de sesión
                      }, 3000); // Espera durante 3 segundos (3000 milisegundos)
                      
                }
            }
            if (err.status === 403) {
                this.toaster.showToast('error', '', 'No tiene permisos para realizar esta acción :(');
            }
          
            if (err.status === 400) {
                if (err.error.details) {
                    let errorDetail = "";
                    for (let msg of err.error.details) {
                        errorDetail += msg + "\n";
                    }
                    this.toaster.showToast('error', 'Error', errorDetail);
                }
                else if(err.error.message) {
                    const message = err.error.message ? err.error.message : genericErrorMessage;
                    this.toaster.showToast('error', 'Error', `${message}`);
                }
                else
                {
                    const message = err.error.detail ? err.error.detail : genericErrorMessage;
                    this.toaster.showToast('warning', 'Atención', `${message}`);
                }
                
            }
            if (err.status === 500) {
                this.toaster.showToast('error', 'Error', `${genericErrorMessage}`);
            }

            // const error = err.error || err.statusText;
            return throwError(err);
        }));
    }

    
    
}
