import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, HostListener } from '@angular/core';
import { DataTableField } from 'src/app/models/datatable-field';
import { PageService } from 'src/app/core/paging/page.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-datatable-base',
    templateUrl: './datatable-base.component.html',
    styleUrls: ['./datatable-base.component.scss']
})
export class DatatableBaseComponent implements OnInit, OnChanges {
    @ViewChild('table') table: ElementRef;
    @Input() public fields: DataTableField[] = [];
    @Input() public data?: Array<any>;
    @Input() public detail?: boolean = false;
    @Input() isGroupping: boolean = false;
    @Output() public detailClick: EventEmitter<any> = new EventEmitter<any>();

    page: number;

    get objectKeys() {
        return Object.keys;
    }
    totalPages: number;

    // Sorting
    @Input() detailTable?: boolean = false;
    @Input() itemsPerPage?: number;
    @Input() maxSize?: number = 5;
    @Input() totalItems?: number;
    @Output() pageChange: EventEmitter<void> = new EventEmitter<void>();
    @Output() sortEmit: EventEmitter<void> = new EventEmitter<void>();
    
    sortingData: any[] = new Array<any>();
    tableData: any[] = [];
    changeEvent: PageChangedEvent;

    sortedField: string;
    sortedAsc: boolean;
    sortedDesc: boolean;

    constructor(private pageService: PageService) { }

    ngOnChanges() {
        let pageSub: Observable<any> = null;
        if (this.detailTable) {
            pageSub = this.pageService.detailPage$;
        }
        else {
            pageSub = this.pageService.page$;
        }
        pageSub.subscribe(pageState => {
            // if (this.itemsPerPage == null || this.itemsPerPage === undefined) {
            // }
            this.itemsPerPage = pageState.itemsPerPage;
            this.totalItems = pageState.totalItems ? pageState.totalItems : this.data.length;
            this.page = pageState.page;
            this.sortedField = pageState.sortProp;
            this.sortedAsc = pageState.sortedAsc;
            this.sortedDesc = pageState.sortedDesc;
            this.changeEvent = {
                page: this.page,
                itemsPerPage: this.itemsPerPage
            };
            this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        });
        this.sortingData = this.data.slice();
        this.pageChanged(this.changeEvent);
    }

    ngOnInit() {
        this.resizeScreenPaginator(window.innerWidth);
    }

    pageChanged(event: PageChangedEvent): void {
        const startItem = this.data.length > event.itemsPerPage ? (event.page - 1) * event.itemsPerPage : 0;
        const endItem = this.data.length > event.itemsPerPage ? event.page * event.itemsPerPage : this.data.length;
        this.tableData = this.data.slice(startItem, endItem);
        const state = this.detailTable ? this.pageService.getDetailValue() : this.pageService.getValue();
        if (event.page !== state.page) {
            state.page = event.page;
            this.pageChange.emit();
            this.detailTable ? this.pageService.setDetailPage(state) : this.pageService.setPage(state);
        }
    }

    sort(field: string) {
        const index = this.fields.findIndex(e => e.name === field);
        const sortable = this.fields[index].sortable !== undefined ? this.fields[index].sortable : true;
        if (this.tableData.length > 0 && field !== 'actions' && sortable) {
            const state = this.pageService.getValue();
            if (this.sortedField !== field) {
                state.sortProp = field;
                state.sortedAsc = true;
                state.sortedDesc = false;
                state.sortDirection = 'asc';
                if (this.sortEmit.observers.length === 0) {
                    this.sortAscending(field);
                }
            }
            else if (this.sortedAsc) {
                state.sortedAsc = false;
                state.sortedDesc = true;
                state.sortDirection = 'desc';
                if (this.sortEmit.observers.length === 0) {
                    this.sortDescending(field);
                }
            }
            else if (this.sortedDesc) {
                if (this.sortEmit.observers.length === 0) {
                    this.data = this.sortingData.slice();
                }
                this.sortedField = '';
                state.sortProp = '';
                this.sortedDesc = false;
                state.sortDirection = '';
            }
            this.detailTable ? this.pageService.setDetailPage(state) : this.pageService.setPage(state);
            this.sortEmit.emit();
                this.pageChanged(this.changeEvent);
            if (this.sortEmit.observers.length === 0) {
                this.pageChanged(this.changeEvent);
            }
        }
    }

    private sortAscending(field: string) {
        this.data = this.sortingData.slice();
        this.data.sort((a, b) => {
            if (a[field] !== undefined) {
                a[field] = a[field] === null || a[field] === undefined ? '' : a[field];
                b[field] = b[field] === null || b[field] === undefined ? '' : b[field];

                if (this.data.every(e => !isNaN(+e[field]))) {
                    return a[field].toString().localeCompare(b[field], 'es-ES', { numeric: 'true' });
                }
                else {
                    return a[field].toString().localeCompare(b[field]);
                }
            }
        });
        this.sortedDesc = false;
        this.sortedAsc = true;
        this.sortedField = field;
    }

    private sortDescending(field: string) {
        this.data.reverse();
        this.sortedAsc = false;
        this.sortedDesc = true;
    }

    itemsChange() {
        let pageState = null;
        if (this.detailTable) {
            pageState = this.pageService.getDetailValue();
        }
        else {
            pageState = this.pageService.getValue();
        }
        pageState.itemsPerPage = Number(this.itemsPerPage);
        this.detailTable ? this.pageService.setDetailPage(pageState) : this.pageService.setPage(pageState);
        this.pageChange.emit();
    }


	@HostListener('window:resize', ['$event'])
	onResize(event) {
	  this.resizeScreenPaginator(window.innerWidth);
	}

    resizeScreenPaginator(innerWithScreen: number){
        if (innerWithScreen >= 768 ) {
            this.maxSize = 5;
          } else if (innerWithScreen < 768) {
            this.maxSize = 3;
          }
    }

}