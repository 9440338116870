import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {

  transform(input: string = ""): string {

    let sentence = input.toLowerCase().split(" ");
    let out = [];
    sentence.forEach(word => {
      if (!this.isPrepositions(word)) {
        if (word.length > 0) {
          out.push(word[0].toUpperCase() + word.slice(1).toLowerCase());
        } else {
          out.push(word.toLowerCase());
        }

      }
      else {
        out.push(word.toLowerCase());
      }
    })

    return out.join(" ");

  }

  private isPrepositions(word: string): boolean {
    let prepositions = ['a', 'ante', 'bajo', 'cabe', 'con', 'contra', 'de', 'del', 'desde', 'durante', 'en', 'entre', 'hacia', 'hasta', 'mediante', 'para', 'por', 'según', 'sin', 'so', 'sobre', 'tras', 'versus', 'vía'];
    return prepositions.includes(word.toLowerCase());
  }

}
