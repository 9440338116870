import { Injectable } from "@angular/core";


const swal = require("sweetalert");


@Injectable({
    providedIn: 'root'
})
export class SwalFactory {

    private static ACCEPT_LABEL: string = "Aceptar";
    private static CANCEL_LABEL: string = "Cancelar";
    private static EXIT_LABEL: string = "Salir";

    private static SWAL_CONFIRM_BUTTON_CLASS: string = "swal-button--confirm";
    private static SWAL_CANCEL_BUTTON_CLASS: string = "swal-button--cancel";



    /*----------------------------------
     *      PUBLIC METHODS
     *----------------------------------*/


    public static closeCurrentSwal() {

        swal.close();
    }


    /*.....................
     *      WARNINGS
     *.....................*/

    /**
     * Este método permite abrir un SweetAlert de tipo warning con botones para "salir" y "cancelar" y
     * recibiendo un titulo.
     *
     * @param title Cadena con el titulo de la notificación.
     * @returns Retorna un Promise para poder ejecutar luego que la notificación se cierra.
     */
    public static openWarningWithTitleExitAndCancelBtn(title: string): Promise<any> {

        const config: SwalConfig = this.getConfigForWarningTilteConfirmBtn(title, this.EXIT_LABEL)
            .addCancelButton(this.CANCEL_LABEL)
            .build();

        const result = this.getSwal(config);
        return result;
    }

    /**
     * Este método permite abrir un SweetAlert de tipo warning con botones para "aceptar" y "cancelar" con sus labels por defdecto,
     * recibiendo un titulo personaloizado.
     *
     * @param title Cadena con el titulo de la notificación.
     * @returns Retorna un Promise para poder ejecutar luego que la notificación se cierra.
     */
    public static openWarningWithTitleCancelAndAcceptBtn(title: string): Promise<any> {

        let config: SwalConfig = this.getConfigForWarningTilteConfirmBtn(title, this.ACCEPT_LABEL)
            .addCancelButton(this.CANCEL_LABEL)
            .build();

        const result = this.getSwal(config);
        return result;
    }

    /**
     * Este método permite abrir un SweetAlert de tipo warning con boton  "aceptar" con label por defecto,
     * recibiendo un titulo y mensaje personalizado.
     *
     * @param title Cadena con el titulo de la notificación.
     * @param message Cadena con el mensaje de la notificación
     * @returns Retorna un Promise para poder ejecutar luego que la notificación se cierra.
     */
    public static openWarningWithTitleMessageAndAcceptBtn(title: string, message: string): Promise<any> {

        return this.openWarningWithOneBtn(title, message, this.ACCEPT_LABEL);
    }

    /**
     * Este método permite abrir un SweetAlert de tipo warning con botones para "aceptar" y "cancelar" con sus labels por defdecto,
     * recibiendo un titulo y un mensaje personaloizado.
     *
     * @param title Cadena con el titulo de la notificación.
     * @param message Cadena con el mensaje de la notificación
     * @param acceptBtnCloseModal Valor Booleano que determina si el popup se debe cerrar con el click
     *                              sobre el botón "Confirmar". Si el valor es True el popup se cerrará al clickear
     *                              si es False no lo hará. El valor por defecto es True.
     * @returns Retorna un Promise para poder ejecutar luego que la notificación se cierra.
     */
    public static openWarningWithTitleMessageCancelAndAcceptBtn(title: string, message: string, acceptBtnCloseModal: Boolean = true): Promise<any> {

        let config: SwalConfig = this.getConfigForWarningTilteConfirmBtn(title, this.ACCEPT_LABEL, acceptBtnCloseModal)
            .addCancelButton(this.CANCEL_LABEL)
            .addMessage(message)
            .build();

        const result = this.getSwal(config);
        return result;
    }

    /**
     *
     * @param title Cadena con el titulo de la notificación.
     * @param message Cadena con el mensaje de la notificación
     * @param content Instancia de contenido HTML para incrustarse en el popup
     * @param acceptBtnCloseModal Valor Booleano que determina si el popup se debe cerrar con el click
     *                              sobre el botón "Confirmar". Si el valor es True el popup se cerrará al clickear
     *                              si es False no lo hará. El valor por defecto es True.
     * @returns Retorna un Promise para poder ejecutar luego que la notificación se cierra.
     */
    public static openWarningWithTitleMessageContentCancelAndAcceptBtn(title: string, message: string, content: any, acceptBtnCloseModal: Boolean = true) {

        let config: SwalConfig = this.getConfigForWarningTilteConfirmBtn(title, this.ACCEPT_LABEL, acceptBtnCloseModal)
            .addCancelButton(this.CANCEL_LABEL)
            .addMessage(message)
            .addContent(content)
            .build();

        const result = this.getSwal(config);
        return result;
    }

    /**
     * Este método permite abrir un SweetAlert de tipo warning con boton para aceptar, recibiendo un titulo y mensaje
     * y label para el boton de confirmación.
     *
     * @param title Cadena con el titulo de la notificación.
     * @param message Cadena con el mensaje de la notificación.
     * @param buttonLabel Cadena con el lable del botón de confirmación.
     * @returns Retorna un Promise para poder ejecutar luego que la notificación se cierra.
     */
    public static openWarningWithOneBtn(title: string, message: string, buttonLabel: string): Promise<any> {

        let config: SwalConfig = this.getConfigForWarningTilteConfirmBtn(title, buttonLabel)
            .addMessage(message)
            .build();

        const result = this.getSwal(config);
        return result;
    }


    /*.....................
     *      SUCCESS
     *.....................*/

    /**
     * Este método permite abrir un SweetAlert de tipo Succes con boton para aceptar, recibiendo un titulo y mensaje
     *
     * @param title Cadena con el titulo de la notificación.
     * @param message Cadena con el mensaje de la notificación.
     * @returns Retorna un Promise para poder ejecutar luego que la notificación se cierra.
     */
    public static openSuccesWithTitleMessageAndAcceptBtn(title: string, message: string) {

        let config: SwalConfig = this.getConfigForSuccessTilteConfirmBtn(title, this.ACCEPT_LABEL)
            .addMessage(message)
            .build();

        const result = this.getSwal(config);
        return result;
    }


    /*.....................
     *      ERROR
     *.....................*/

    /**
     * Este método permite abrir un SweetAlert de tipo Error  con boton para aceptar, recibiendo un titulo y mensaje
     *
     * @param title Cadena con el titulo de la notificación.
     * @param message Cadena con el mensaje de la notificación.
     * @returns Retorna un Promise para poder ejecutar luego que la notificación se cierra.
     */
    static openErrorWithTitleMessageAndAcceptBtn(title: string, message: string) {

        let config: SwalConfig = this.getConfigForErrorTilteConfirmBtn(title, this.ACCEPT_LABEL)
            .addMessage(message)
            .build();

        const result = this.getSwal(config);
        return result;
    }



    /*----------------------------------
     *      PRIVATE METHODS
     *----------------------------------*/

    /**
     * Permite contruir una configuración por básica para una notificación de tipo warning recibiendo
     * un titulo y un lable de botón de confirmación.
     *
     * @param title Cadena con el titulo de la notificación.
     * @param confimButtonLabel  Cadena con el lable del botón de confirmación.
     * @param acceptBtnCloseModal Valor Booleano que determina si el popup se debe cerrar con el click
     *                              sobre el botón "Confirmar". Si el valor es True el popup se cerrará al clickear
     *                              si es False no lo hará. El valor por defecto es True.
     * @returns Retorna un objeto con la configuración para una notificación de tipo warning con
     *          un titulo y un botón de confirmación.
     */
    private static getConfigForWarningTilteConfirmBtn(title: string, confimButtonLabel: string, acceptBtnCloseModal: Boolean = true): SwalConfigurationBuilder {

        return new SwalConfigurationBuilder()
            .addTitle(title)
            .addIcon("warning")
            .addConfirmButton(confimButtonLabel, acceptBtnCloseModal);
    }

    /**
   * Permite contruir una configuración por básica para una notificación de tipo Succes recibiendo
   * un titulo y un lable de botón de confirmación.
   *
   * @param title Cadena con el titulo de la notificación.
   * @param confimButtonLabel  Cadena con el lable del botón de confirmación.
   * @param acceptBtnCloseModal Valor Booleano que determina si el popup se debe cerrar con el click
   *                              sobre el botón "Confirmar". Si el valor es True el popup se cerrará al clickear
   *                              si es False no lo hará. El valor por defecto es True.
   * @returns Retorna un objeto con la configuración para una notificación de tipo warning con
   *          un titulo y un botón de confirmación.
   */
    private static getConfigForSuccessTilteConfirmBtn(title: string, confimButtonLabel: string, acceptBtnCloseModal: Boolean = true): SwalConfigurationBuilder {

        return new SwalConfigurationBuilder()
            .addTitle(title)
            .addIcon("success")
            .addConfirmButton(confimButtonLabel, acceptBtnCloseModal);
    }



    /**
   * Permite contruir una configuración por básica para una notificación de tipo Error recibiendo
   * un titulo y un lable de botón de confirmación.
   *
   * @param title Cadena con el titulo de la notificación.
   * @param confimButtonLabel  Cadena con el lable del botón de confirmación.
   * @param acceptBtnCloseModal Valor Booleano que determina si el popup se debe cerrar con el click
   *                              sobre el botón "Confirmar". Si el valor es True el popup se cerrará al clickear
   *                              si es False no lo hará. El valor por defecto es True.
   * @returns Retorna un objeto con la configuración para una notificación de tipo warning con
   *          un titulo y un botón de confirmación.
   */
    private static getConfigForErrorTilteConfirmBtn(title: string, confimButtonLabel: string, acceptBtnCloseModal: Boolean = true): SwalConfigurationBuilder {

        return new SwalConfigurationBuilder()
            .addTitle(title)
            .addIcon("error")
            .addConfirmButton(confimButtonLabel, acceptBtnCloseModal);
    }


    /**
     *
     * @param config
     * @returns
     */
    private static getSwal(config: SwalConfig): any {

        const swalResponse = swal(config);

        this.setButtonId(this.SWAL_CONFIRM_BUTTON_CLASS, config.buttons.confirm);
        this.setButtonId(this.SWAL_CANCEL_BUTTON_CLASS, config.buttons.cancel);

        return swalResponse
    }

    /**
     *
     * @param classNameFilter
     * @param config
     */
    private static setButtonId(classNameFilter: string, config?: SwalConfigButton) {

        if (config) {

            const buttons: HTMLCollectionOf<Element> = document.getElementsByClassName(classNameFilter);
            for (const tag of Array.from(buttons)) {
                tag.setAttribute("id", config.id);
            }
        }
    }
}








class SwalConfigurationBuilder {

    private static SWAL_CANCEL_BUTTON_ID: string = "swal-button-cancel";
    private static SWAL_CONFIRM_BUTTON_ID: string = "swal-button-confirm";

    private _target: SwalConfig;




    constructor() {

        this._target = { buttons: {} };
    }


    public build(): SwalConfig { return this._target; }

    public addTitle(title: string): SwalConfigurationBuilder {

        this._target.title = title;
        return this;
    }

    public addMessage(message: string): SwalConfigurationBuilder {

        this._target.text = message;
        return this;
    }

    public addContent(content: any): SwalConfigurationBuilder {

        this._target.content = content;
        return this;
    }

    public addTitlteAndMessage(title: string, message: string): SwalConfigurationBuilder {

        this._target.title = title;
        this._target.text = message;

        return this;
    }

    public addIcon(icon: string): SwalConfigurationBuilder {

        this._target.icon = icon;
        return this;
    }

    public addConfirmButton(label: string, closeModal: Boolean = true): SwalConfigurationBuilder {

        let confirm: SwalConfigButton = {
            text: label,
            value: true,
            visible: true,
            className: 'btn-primary',
            closeModal: closeModal,
            id: SwalConfigurationBuilder.SWAL_CONFIRM_BUTTON_ID
        };

        this._target.buttons.confirm = confirm;

        return this;
    }

    public addCancelButton(label: string): SwalConfigurationBuilder {

        let cancel: SwalConfigButton = {
            text: label,
            value: null,
            visible: true,
            className: 'btn-secondary',
            closeModal: true,
            id: SwalConfigurationBuilder.SWAL_CANCEL_BUTTON_ID
        };

        this._target.buttons.cancel = cancel;

        return this;
    }
}

interface SwalConfig {

    title?: string;
    text?: string;
    icon?: string;
    content?: any;
    buttons: SwalConfigButtonSet;
}

interface SwalConfigButton {

    text: string;
    value?: any;
    visible?: Boolean;
    className?: string;
    closeModal: Boolean;
    id: string;
}

interface SwalConfigButtonSet {

    cancel?: SwalConfigButton;
    confirm?: SwalConfigButton;
}
