import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const API_URL = `${environment.apiUrl}/Device`;

@Injectable({
    providedIn: 'root'
})
export class DeviceOwnersService {
    private ownerSource = new BehaviorSubject([]);
    owners = this.ownerSource.asObservable();

    constructor(private http: HttpClient) { }

    setOwners(owners) {
        this.ownerSource.next(owners);
    }
    getValue() {
        return this.ownerSource.getValue();
    }
    getOwners() {
        return this.http.get<any>(API_URL + '/GetOwners');
    }
}
