import { Component, OnDestroy, OnInit } from '@angular/core';
import { delay, take, skipWhile } from 'rxjs/operators';
import { ToasterConfig, ToasterService } from 'angular2-toaster';
import { ToasterNotificationService } from '../core/toaster/toaster-notification.service';
import { AppContextService } from '../core/context/app-context.service';
import { ThemesService } from '../core/themes/themes.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {

  currentTheme: any;
  showLoader: boolean;

  suscriptionToaster: any;
  suscriptionLoader: any;

  // TOASTER
  toaster: any;
  toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-center',
    showCloseButton: true,
    preventDuplicates: false
  });

  constructor(
    private toasterService: ToasterService,
    private toasterNotification: ToasterNotificationService,
    public themes: ThemesService, 
    private appContext: AppContextService) {
      this.currentTheme = themes.getDefaultTheme();
     }

  ngOnInit(): void {

    this.suscriptionLoader = this.appContext.asyncCall$.pipe(
      // ValueChangedAfterDetection Error Fix
      delay(0)
    )
      .subscribe(data => {
        this.showLoader = data;
      });

    this.suscriptionToaster = this.toasterNotification.toaster$
      .pipe(
        skipWhile(t => t == null || t === undefined)
      )
      .subscribe(toast => {
        this.toaster = toast;
        this.pop();
      });
  }

  pop() {
    this.toasterService.pop(this.toaster.type, this.toaster.title, this.toaster.text);
  }

  ngOnDestroy() {
    this.suscriptionToaster.unsubscribe();
    this.suscriptionLoader.unsubscribe();
  }
}