export class PageState {
    page: number = 1;
    prevPage: number;
    itemsPerPage: number = 10;
    prevItemsPerPage: number;
    totalItems: number;
    sortProp: string = '';
    sortDirection: string = '';
    sortedAsc: boolean = false;
    sortedDesc: boolean = false;
}
