import { DatePipe, DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";


/**
 * Esta clase tiene como responsabilidad formatear la informacion recuperada de la bd para ser visualizada 
 * en el formato correcto indiicado en la configuracion del reporte.
  *
 */
@Injectable({
    providedIn: 'root'
})
export class FormatField {

    //#region :::::: PUBLIC METHODS :::::::

    /**
     * Este método permite cerrar un Popup Swal que esté actualmente desplegado.
     */
    public static formatProperty(row: number, field: any, value) {
        var fieldFormatted = value;
        
        if (field.type === 'datetime' && value) {
            fieldFormatted =  new DatePipe('es').transform(value, field.format ? field.format : 'dd/MM/yyyy H:mm:ss');
        }
        if (field.type === 'date' && value) {
            fieldFormatted = new DatePipe('es').transform(value, field.format ? field.format : 'dd/MM/yyyy');
        }
        if ((field.name === 'summ' || field.name === 'amount' || field.type === 'decimal') && value !== null) {
            fieldFormatted = new DecimalPipe('es').transform(value, (field.format && field.format != 'null') ? field.format : '1.2-2');
        }
      
        return fieldFormatted;
    }
}

