import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay, tap } from 'rxjs/operators';

const API_URL = `${environment.apiUrl}/device`;

@Injectable({
    providedIn: 'root'
})
export class DeviceTypesService {

    private typeSource = new BehaviorSubject([]);
    public types$ = this.typeSource.asObservable();

    constructor(private http: HttpClient) { }

    public getTypes(): Observable<any[]> {

        return this.http.get<any>(API_URL + '/types')
            .pipe(
                shareReplay(),
                map(response => response.devices),
                tap(types => this.typeSource.next(types))
            );
    }


    public getValue(): any[] {

        return this.typeSource.getValue();
    }
}
