import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Transaction } from 'src/app/models/transaction';
import { TransactionDetail } from 'src/app/models/transaction-detail';

@Injectable({
    providedIn: 'root'
})
export class SelectedService {
    private transaction: TransactionDetail = null;
    private _selectedTransaction: BehaviorSubject<TransactionDetail> = new BehaviorSubject<TransactionDetail>(this.transaction);
    selectedTransaction$ = this._selectedTransaction.asObservable();

    constructor() { }

    setSelected(transaction: TransactionDetail) {
        this._selectedTransaction.next(transaction);
    }

    cleanSelected() {
        this.transaction = null;
        this._selectedTransaction.next(this.transaction);
    }

    getValue() {
        return this._selectedTransaction.getValue();
    }
}
