import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, AbstractFormGroupDirective, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

@Component({ template: '' })
export default abstract class AbstractFormHolder implements OnDestroy {

    private posting: Boolean = false;
    private subscription: Subscription;

    public get postingForm(): Boolean {

        return this.posting;
    }


    ngOnDestroy(): void {

        this.deactivatePostingForm();

        if (this.subscription) {

            this.subscription.unsubscribe();
        }
    }

    public canShowError(control: AbstractControl): Boolean {

      //  return control.invalid && (control.dirty || this.pPpostingForm);
		return control.invalid && this.posting;
    }

    public canShowRequiredError(control: AbstractControl): Boolean {

        return control.errors?.required && this.canShowError(control);
    }

    public canShowPatternError(control: AbstractControl): Boolean {

        return control.errors?.pattern && this.canShowError(control);
    }

	public canShowMinError(control: AbstractControl): Boolean {
		
		return control.errors?.['minlength'] && this.canShowError(control);
    }

    public canShowMaxError(control: AbstractControl): Boolean {

		return control.errors?.['maxlength'] && this.canShowError(control);
    }

    public canShowInvalidEmailError(control: AbstractControl): Boolean {

        return control.errors?.email && this.canShowError(control);
    }


    protected setupFormGroup(formGroup: FormGroup): void {
		
		this.posting = false;
        //Escucha los cambios realizados en el formGroup para resetear el estado del form.
		this.subscription = formGroup.valueChanges.pipe(distinctUntilChanged()).subscribe(value => this.onFormChanges());
    }

    protected activatePostingForm() {

        this.posting = true;
    }

    protected deactivatePostingForm() {

        this.posting = false;
    }

    private onFormChanges(): void {

        this.deactivatePostingForm();
    }
}
