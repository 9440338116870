import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-time-clock',
  templateUrl: './date-time-clock.component.html',
  styleUrls: ['./date-time-clock.component.scss']
})
export class DateTimeClockComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    moment.locale('es')
  }

}
