import { AuthComponent } from '../auth/auth.component';
import { LayoutComponent } from '../layout/layout.component';

export const routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'inicio', pathMatch: 'full' },
            {
                path: 'inicio',
                loadChildren: () => import('../home/home.module').then(mod => mod.HomeModule)
            },
            // Afiliados
            {
                path: 'afiliados',
                loadChildren: () => import('../affiliates/affiliates.module').then(mod => mod.AffiliatesModule)
            }, 
            // Transacciones
            {
                path: 'transacciones',
                loadChildren: () => import('../transactions/transactions.module').then(mod => mod.TransactionsModule)
            },
            // Equipos
            {
                path: 'equipos',
                loadChildren: () => import('../devices/devices.module').then(mod => mod.DevicesModule)
            },
            // Solicitudes
            {
                path: 'solicitudes',
                loadChildren: () => import('../device-ticket/device-ticket.module').then(mod => mod.DeviceTicketModule)
            },
            // Cuenta
            {
                path: 'cuenta',
                loadChildren: () => import('../account/account.module').then(mod => mod.AccountModule)
            },
            // Reportes Dinámicos
            {
                path: 'reportes/:name',
                loadChildren: () => import('../reportes-dinamicos/dynamic-reports.module').then(mod => mod.DynamicReportsModule)
            },
            // Generacion de claves
            {
                path: 'claves/generar-claves',
                loadChildren: () => import('../installation-keys/installation-keys.module').then(mod => mod.InstallationKeysModule)
            },
            // Notificaciones
            {
                path: 'notificaciones',
                loadChildren: () => import('../notifications/notification-app.module').then(mod => mod.NotificationsAppModule)
            }
        ]
    },
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'auth',
                loadChildren: () => import('../auth/auth.module').then(mod => mod.AuthModule)
            }
        ]
    },
    // Not found
    { path: '**', redirectTo: 'inicio' }
];