<div id='filterDiv' class="d-block d-md-none">
    <button class="btn btn-secondary btn-lg mt-2 mb-2 icon-search" (click)="clickFilter()" type="button" id="filtersBtn">
         Mostrar Filtros 
      </button>
</div>


<div class="card card-default card-body mt-4 d-none d-md-block" id="filtersForm">
    <form [formGroup]="filterForm">

        <div class="form-group row">

            <div class="col-lg-3 col-sm-6 col-12" *ngFor="let filter of filters; let i = index">

                <label class="col-form-label">{{filter.title}}</label>

                <input *ngIf="!filter.items && !filter.type" type="text" class="form-control" name="{{filter.name}}"
                    id="{{filter.name}}" placeholder="{{filter.placeholder ? filter.placeholder : filter.title}}"
                    [value]="filter.value" formControlName="{{filter.name}}">

                <input *ngIf="filter.type === 'Text'" type="text" class="form-control" name="{{filter.name}}" id="{{filter.name}}"
                    placeholder="{{filter.placeholder ? filter.placeholder : filter.title}}" [value]="filter.value"
                    formControlName="{{filter.name}}">

                <input *ngIf="filter.type === 'Numeric'" type="number" min="0" appDigitsOnly class="form-control"
                    name="{{filter.name}}" id="{{filter.name}}"
                    placeholder="{{filter.placeholder ? filter.placeholder : filter.title}}" [value]="filter.value"
                    formControlName="{{filter.name}}" [ngClass]="{ 'invalid': !filterForm.controls[filter.name].valid }"
                    (change)="validateNumerics(filter.name)">
                <p class="text-danger m-0"
                    *ngIf="filter.type === 'Numeric' && (filterForm.controls[filter.name].errors && filterForm.controls[filter.name].errors.min)">
                    El valor no puede ser menor a {{filterForm.controls[filter.name].errors.min.min}}</p>
                <p class="text-danger m-0"
                    *ngIf="filter.type === 'Numeric' && (filterForm.controls[filter.name].errors && filterForm.controls[filter.name].errors.max)">
                    El valor no puede ser mayor a {{filterForm.controls[filter.name].errors.max.max}}</p>

                <input *ngIf="filter.type === 'Date'" type="date" class="form-control" name="{{filter.name}}"
                    id="{{filter.name}}" placeholder="{{filter.placeholder ? filter.placeholder : filter.title}}"
                    [value]="filter.value" formControlName="{{filter.name}}" min="{{filter.minDate ? filter.minDate : null}}" max="{{filter.maxDate ? filter.maxDate : null}}" (keydown)="filter.readonly ? onKeyDown($event) : null"
                    [ngClass]="{ 'invalid': !filterForm.controls[filter.name].valid }"
                    (change)="validateDates(filter.name)">

                <input *ngIf="filter.type === 'DateFrom'" type="date" class="form-control" name="{{filter.name}}"
                    id="{{filter.name}}" placeholder="{{filter.placeholder ? filter.placeholder : filter.title}}"
                    [value]="filter.value" formControlName="{{filter.name}}"
                    [ngClass]="{ 'invalid': !filterForm.controls[filter.name].valid }"
                    (change)="validateDates(filter.name)" min="{{filter.minDate ? filter.minDate : null}}" max="{{filter.maxDate ? filter.maxDate : null}}"
                    (keydown)="filter.readonly ? onKeyDown($event) : null">
                <p class="text-danger m-0"
                    *ngIf="filter.type === 'DateFrom' && (filterForm.controls[filter.name].errors && filterForm.controls[filter.name].errors.dateMaximum)">
                    Fecha desde es mayor a fecha hasta</p>

                <input *ngIf="filter.type === 'DateTo'" type="date" class="form-control" name="{{filter.name}}"
                    id="{{filter.name}}" placeholder="{{filter.placeholder ? filter.placeholder : filter.title}}"
                    [value]="filter.value" formControlName="{{filter.name}}"
                    [ngClass]="{ 'invalid': !filterForm.controls[filter.name].valid }"
                    (change)="validateDates(filter.name)" min="{{filter.minDate ? filter.minDate : null}}" max="{{filter.maxDate ? filter.maxDate : null}}"
                    (keydown)="filter.readonly ? onKeyDown($event) : null">
                <p class="text-danger m-0"
                    *ngIf="filter.type === 'DateTo' && (filterForm.controls[filter.name].errors && filterForm.controls[filter.name].errors.dateMinimum)">
                    Fecha hasta es menor a fecha desde</p>

                <input *ngIf="filter.type === 'DateTimeFrom'" type="datetime-local" class="form-control"
                    name="{{filter.name}}" id="{{filter.name}}"
                    placeholder="{{filter.placeholder ? filter.placeholder : filter.title}}" [value]="filter.value"
                    formControlName="{{filter.name}}" [ngClass]="{ 'invalid': !filterForm.controls[filter.name].valid }"
                    (change)="validateDates(filter.name)">
                <p class="text-danger m-0"
                    *ngIf="filter.type === 'DateTimeFrom' && (filterForm.controls[filter.name].errors && filterForm.controls[filter.name].errors.dateMaximum)">
                    Fecha desde es mayor a fecha hasta</p>

                <input *ngIf="filter.type === 'DateTimeTo'" type="datetime-local" class="form-control"
                    name="{{filter.name}}" id="{{filter.name}}"
                    placeholder="{{filter.placeholder ? filter.placeholder : filter.title}}" [value]="filter.value"
                    formControlName="{{filter.name}}" [ngClass]="{ 'invalid': !filterForm.controls[filter.name].valid }"
                    (change)="validateDates(filter.name)">
                <p class="text-danger m-0"
                    *ngIf="filter.type === 'DateTimeTo' && (filterForm.controls[filter.name].errors && filterForm.controls[filter.name].errors.dateMinimum)">
                    Fecha hasta es menor a fecha desde</p>

                <select *ngIf="filter.items" class="form-control" formControlName="{{filter.name}}"  id="{{filter.name}}">
                    <option>Todos</option>
                    <option *ngFor="let option of filter.items">{{option}}</option>
                </select>

                <ng-container class="custom" *ngIf="filter.type === 'List'">
                    <ng-select [items]="filter.listItems" bindLabel="description" bindValue="id" formControlName="{{filter.name}}" id="{{filter.name}}"  placeholder="{{filter.placeholder ? filter.placeholder : filter.title}}">
                    </ng-select>
                </ng-container>

                <ng-container class="custom" *ngIf="filter.type === 'ListAddTag'">
                    <ng-select [items]="filter.listItems" bindLabel="description" bindValue="id" [addTag]="addTag.bind(this)" addTagText="Clic para buscar"
                            formControlName="{{filter.name}}" id="{{filter.name}}"  placeholder="{{filter.placeholder ? filter.placeholder : filter.title}}" 
                            (change)="onChange($event, filter.name)">
                    </ng-select>
                </ng-container>

            
            <div *ngIf="filter.type === 'Switch'" class="d-flex align-items-start mb-3">
                <div class="mr-auto">
                    <label class="switch switch-lg m-0">
                        <input type="checkbox" checked formControlName="{{filter.name}}" id="{{filter.name}}" />
                        <span></span>
                    </label>
                </div>
            </div>

            <p class="text-danger m-0" *ngIf="filter.required && !filterForm.controls[filter.name].valid && filterForm.controls[filter.name].dirty"></p>


            </div>

            <div class="col align-self-end text-right" style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center;">
                <button class="btn btn-primary btn-lg mt-2 ml-2" type="button" id="searchBtn" (click)="search()"><em class="fa fas fa-search mr-3">
                  </em>
                  Buscar
                </button>
                <button class="btn btn-secondary btn-lg mt-2 ml-2" (click)="clean()" type="button" id="cleanBtn">
                  <em  class="fa-lg fas fa-eraser"></em>
                </button>
            </div>
        </div>
        <div class="row" [hidden]="!mandatoryFilters">
            <div class="col small">
                <em>Los campos marcados con <strong>*</strong> son obligatorios.</em>
            </div>
        </div>
    </form>
</div>
