<ng-container *ngIf="isLoaded; else loading">

    <table class="table" *ngIf="data.length > 0; else elseBlock">
        <tbody>
            <ng-container>
                <ng-container *ngFor="let rowGroup of data | keyvalue">
                    <tr class="font-weight-bold"><td colspan=2 class="info" >Lote:  {{rowGroup.value[0].batch}}</td></tr>
                        <tr *ngFor="let row of rowGroup.value; let j = index">
                        <td>
                            <p class="mb-1 text-sm">
                                {{row.createdOn | date:'dd/MM/yyyy hh:mm a'}}
                            </p>
                            <p class="mb-1 text-sm">
                                <span *ngIf="row.transactionResult == 'Accepted'"
                                    class="circle bg-success circle-lg text-left"></span>
                                <span *ngIf="row.transactionResult!= 'Accepted'"
                                    class="circle bg-warning circle-lg text-left"></span>   
                                <span *ngIf="row.isReverse">Reverso - </span>   
                                <span *ngIf="!row.isReverse">Compra - </span>   
                                    {{row.transactionResultDescription }}
                            </p>
                        </td>
                        <td class="align-middle td-light text-right">
                            <p class="h4">
                                <span class="align-middle"><small>Bs.</small> {{formatProp(row.amount)}}</span>
                            </p>
                        </td>
                        </tr>  
                </ng-container>
            </ng-container>
        </tbody>
    </table>

    <ng-template #elseBlock>
        <div class="alert alert alert-light bg-gray-light m-2 text-center">{{message}}</div>
    </ng-template>

</ng-container>

<ng-template #loading>
    <div class="ball-pulse text-center p-5">
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>