import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export class DateValidator {
    static dateMinimum(date: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null) {
                return null;
            }

            const controlDate = moment.utc(control.value);

            if (!controlDate.isValid()) {
                return null;
            }

            const validationDate = moment.utc(date);

            return controlDate.isSameOrAfter(validationDate) ? null : {
                'dateMinimum': {
                    'date-minimum': validationDate.format('yyyy-MM-dd'),
                    'actual': controlDate.format('yyyy-MM-dd')
                }
            };
        };
    }
    static dateMaximum(date: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null) {
                return null;
            }

            const controlDate = moment.utc(control.value);

            if (!controlDate.isValid()) {
                return null;
            }

            const validationDate = moment.utc(date);

            return controlDate.isSameOrBefore(validationDate) ? null : {
                'dateMaximum': {
                    'date-maximum': validationDate.format('yyyy-MM-dd'),
                    'actual': controlDate.format('yyyy-MM-dd')
                }
            };
        };
    }
}
