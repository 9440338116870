import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaymentGroup } from 'src/app/models/payment-group';

const API_URL = `${environment.apiUrl}/BioPos`;

@Injectable({
    providedIn: 'root'
})
export class PaymentGroupService {
    private paymentGroups = [];
    private _paymentGroups: BehaviorSubject<PaymentGroup[]> = new BehaviorSubject<PaymentGroup[]>(this.paymentGroups);
    paymentGroups$ = this._paymentGroups.asObservable();

    constructor(private http: HttpClient) { }

    getPaymentGroups(){
        return this.http.get<any>(`${API_URL}/GetAllPaymentGroup`);
    }

    setPaymentGroups(paymentGroups) {
        this._paymentGroups.next(paymentGroups);
    }


    getValue() {
         return this._paymentGroups.getValue();
    }
}
